import orange from '@material-ui/core/colors/deepOrange';
import { Theme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

export class ThemeMuiProvider {

    public static provideTheme = (): Theme => {
        const palette = createPalette({
            primary: { main: '#303d4d' },
            secondary: orange,
            type: 'light',
        });

        const typography = createTypography(palette, {
            button: { textTransform: "none" },
        });

        return createTheme({
            palette, typography,
            overrides: {
                MuiButton: {
                    root: {
                        textTransform: 'none',
                    },
                },
            }
        });
    }
}