/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export type EnhancedViewState<T extends {}> = Partial<T> & Partial<{ loading: boolean; modified: boolean; saving: boolean; busy: boolean }>;

export interface UseViewStateResult<TState extends {}> {
    readonly initialState: EnhancedViewState<TState>;
    readonly getState: () => EnhancedViewState<TState>;
    readonly onChange: (field: keyof EnhancedViewState<TState>, value: any, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;
    readonly update: (values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;
    readonly setInitialState: (values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;
}

export const useViewState = <TState extends {}>(initialState: EnhancedViewState<TState> = {}): UseViewStateResult<TState> => {

    const refState = React.useRef<EnhancedViewState<TState>>(initialState);
    const [state, notifyState] = React.useState<EnhancedViewState<TState>>(refState.current);

    const getState = (): EnhancedViewState<TState> => refState.current;

    const setState = (newState: EnhancedViewState<TState>): void => {
        notifyState(refState.current = newState);
    };

    const onChange = (field: keyof EnhancedViewState<TState>, value: any, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        const values = { [field]: value, modified: true } as Partial<EnhancedViewState<TState>>;
        update(values, afterUpdate);
    };

    const update = (values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        const newState: EnhancedViewState<TState> = { ...getState(), ...values };
        setState(newState);
        if (afterUpdate) {
            afterUpdate(newState);
        }
    };


    const setInitialState = (values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        const newState: EnhancedViewState<TState> = { ...initialState, ...values };
        setState(newState);
        if (afterUpdate) {
            afterUpdate(newState);
        }
    };

    return { initialState, setInitialState, getState, onChange, update };
}