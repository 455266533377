import { MeAction, MeActions } from './MeActions';
import { MeState } from './MeState';

const initialState: MeState = {
    initialized: false,
    data: undefined,
};

export const MeReducer = (state = initialState, action: MeAction): MeState => {
    switch (action.type) {
        default:
            return state;
        case MeActions.SET_INFO:
            return {
                ...state,
                initialized: action.payload.initialized,
                data: action.payload.info
            };
    }
};
