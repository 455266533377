import { ApiDataResponse, ApiPaginatedListResponse } from '../ApiData';

export enum VersionType {
    Carpets = 'Carpets',
    Escaliers = 'Escaliers',
    Doormats = 'Doormats',
}

export interface CreatorDto {
    readonly id: string;
    readonly first_name: string;
    readonly last_name: string;
}

export interface VersionActionDto {
    readonly date: string;
    readonly by_user: CreatorDto;
}

export interface VersionDto {
    readonly id: string;
    readonly label: string;
    readonly etat: VersionState;
    readonly created: VersionActionDto;
    readonly question_count: number;
}

export interface VersionDetailsDto {
    readonly id: string;
    readonly label: string;
    readonly etat: VersionState;
    readonly created: VersionActionDto;
    readonly published: VersionActionDto;
    readonly obsoleted: VersionActionDto;
    readonly comment: string;
}

export interface CreateVersionDto {
    readonly label: string;
}

export interface DuplicateVersionParams {
    readonly name: string;
}

export interface UpdateVersionDto {
    readonly label: string;
    readonly etat: VersionState;
    readonly comment: string;
}

export interface ImportVersionDto {
    readonly file: File;
    readonly name: string;
}

export enum VersionState {
    Publiee = 'Publiee',
    Brouillon = 'Brouillon',
    Obsolete = 'Obsolete',
}

export interface ConfigurationDto {
    readonly front_office_host: string;
}

export interface ImportVersionParams {
    name: string;
}

export type VersionListResponse = ApiPaginatedListResponse<VersionDto>;
export type VersionDetailsResponse = ApiDataResponse<VersionDetailsDto>;
export type ConfigurationsResponse = ApiDataResponse<ConfigurationDto>;
