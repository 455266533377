import './BaseDialog.scss';

import { DialogProps, DialogTitle, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import React from 'react';

export interface BaseDialogProps extends DialogProps {
    title?: string;
    content?: React.ReactNode;
    onClose?(): void;
    hideCloseButton?: boolean;
    renderTitle?: () => React.ReactNode;
    children: React.ReactNode;
    rounded?: boolean;
    baseDialogClasses?: {
        header?: string;
        body?: string;
    };
}

export const BaseDialog = ({
    title,
    content,
    onClose,
    children,
    hideCloseButton = false,
    renderTitle,
    rounded = true,
    baseDialogClasses,
    ...rest
}: BaseDialogProps) => {
    //#region //* CLASSESS

    const enhanceBaseDialogPaperClasses = classNames({
        'BaseDialog-paper': true,
        rounded,
    });

    const useOverrideHeaderClass = baseDialogClasses?.header;
    const enhancedBaseDialogHeaderClasses = classNames({
        'BaseDialog-header': !useOverrideHeaderClass,
        [baseDialogClasses?.header ?? '']: useOverrideHeaderClass,
    });

    const useOverrideBodyClass = baseDialogClasses?.body;
    const dialogBodyClasses = classNames({
        'BaseDialog-body': !useOverrideBodyClass,
        [baseDialogClasses?.body ?? '']: useOverrideBodyClass,
    });

    //#endregion

    const RenderDialogTitle = (): React.ReactNode => (
        <React.Fragment>
            {title && <DialogTitle className="BaseDialog-header-title">{title}</DialogTitle>}
        </React.Fragment>
    );

    return (
        <Dialog
            onClose={onClose}
            {...rest}
            classes={{ root: 'BaseDialog', paper: enhanceBaseDialogPaperClasses }}
        >
            <div className="BaseDialog-content">
                <div className={enhancedBaseDialogHeaderClasses}>
                    <IconButton classes={{ root: 'BaseDialog-header-btn-close' }} onClick={onClose}>
                        {/* {Icons.IconCloseWhite()} */}
                    </IconButton>
                    {renderTitle ? renderTitle() : RenderDialogTitle()}
                </div>
                <div className={dialogBodyClasses}>{content || children}</div>
            </div>
        </Dialog>
    );
};
