import "./AlertDialog.scss";

import { DialogActions } from "@material-ui/core";
import React from "react";

import { CustomButton } from "../../CustomButton/CustomButton";
import { BaseDialog } from "../BaseDialog/BaseDialog";

export interface AlertDialogProps {
  title: string;
  open: boolean;
  onClose(): void;

  message: React.ReactNode;
  actionLabel: React.ReactNode;
  doAction(): void;
}

export const AlertDialog: React.FunctionComponent<AlertDialogProps> = ({
  message,
  actionLabel,
  doAction,

  ...rest
}: AlertDialogProps) => {
  return (
    <BaseDialog {...rest} scroll="body" rounded={false}>
      <div className="AlertDialog-content">
        <div className="AlertDialog-message">{message}</div>

        <DialogActions classes={{ root: "AlertDialog-btn-container" }}>
          <CustomButton
            title={actionLabel}
            onClick={doAction}
            classes={{ root: "AlertDialog-btn" }}
          />
        </DialogActions>
      </div>
    </BaseDialog>
  );
};
