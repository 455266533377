import { HttpStatusCode } from './HttpStatusCode';
import { XHRManagedError } from './XHRManagedError';

export interface CustomHttpErrors {
    isApiError: boolean;
    httpStatusCode: HttpStatusCode;
    errorCode: string;
    errorMessage: string;
    checkError: boolean;
}

export enum ErrorHandlerErrorType {
    XHRManagedError = 'XHRManagedError'
}

export interface IErrorHandler {
    handle: (error: any, customErrorHandler?: (exception: any) => boolean) => Promise<boolean>;
}

class ErrorHandler implements IErrorHandler {
    ERROR_HANDLED: boolean = true;
    ERROR_NOT_HANDLED: boolean = false;

    public handle = async (
        error: any,
        customErrorHandler?: (error: any) => boolean
    ): Promise<boolean> => {
        return Promise.resolve(this.ERROR_HANDLED);
    };

    public static getCustomHttpErrors = (error: any): CustomHttpErrors => {

        if (error.errorType === ErrorHandlerErrorType.XHRManagedError) {
            const managedError = error as XHRManagedError;
            return {
                httpStatusCode: managedError.httpStatusCode,
                errorCode: managedError.errorCode,
                isApiError: !!managedError.isApiError,
                errorMessage: managedError.errorMessage,
                checkError: managedError.checkError,
            };
        } else {

            const { config, response } = error;

            //* Error can be trigged by axios interceptor or
            //* Rejected by apiClient and logged to user as a wrapped error
            //* in this case config, response are in object called errorResponse
            const checkError = config?.checkError || true;

            const error_code = response?.error_code || response?.data?.error_code || '';
            const error_message = response?.error_message || response?.data?.error_message || '';
            const status = response?.status;

            const httpStatusCode = status as HttpStatusCode;

            return {
                httpStatusCode,
                errorCode: error_code,
                isApiError: !!httpStatusCode || error_code || error_message,
                errorMessage: error_message,
                checkError,
            };
        }
    };
}
const ErrorHandlerName = 'ErrorHandler';

export { ErrorHandler, ErrorHandlerName };
