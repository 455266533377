import { Route } from 'react-router-dom';

import { AppRoutes } from '../../Core/App/AppRoutes';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { ForgotPasswordPageLoading } from '../Pages/Auth/ForgotPassword/ForgotPasswordPageLoading';
import { LoginPageLoading } from '../Pages/Auth/Login/LoginPageLoading';
import { ResetPasswordPageLoading } from '../Pages/Auth/ResetPassword/ResetPasswordPageLoading';

export class AuthRoutes {
    private static getLoginRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AppRoutes.LOGIN,
            component: LoginPageLoading,
        },
    });

    private static getForgotPasswordRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AppRoutes.FORGOT_PASSWORD,
            component: ForgotPasswordPageLoading,
        },
    });

    private static getResetPasswordRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AppRoutes.RESET_PASSWORD,
            component: ResetPasswordPageLoading,
        },
    });

    private static getInitPasswordRoute = (): RouteItem => ({
        render: Route,
        props: {
            path: AppRoutes.INIT_PASSWORD,
            component: ResetPasswordPageLoading,
        },
    });

    public static getAuthRoutes = () => [
        AuthRoutes.getLoginRoute(),
        AuthRoutes.getForgotPasswordRoute(),
        AuthRoutes.getResetPasswordRoute(),
        AuthRoutes.getInitPasswordRoute(),
    ];
}
