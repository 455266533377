import * as analyticsLogger from 'loglevel';
import { Constants } from '../Constants';
import { LogLevel } from '../LogsSender/LogLevel';
import { LogsSender } from '../LogsSender/LogsSender';



export class Logger {

    public static isLogEnabled = (): boolean => Constants.isLocalDev || Boolean(localStorage.getItem('csm-devDebug'));

    public static log(...msg: any[]) {
        Logger.isLogEnabled() && console.log(msg);
        analyticsLogger.info(msg);
    }

    public static debug(message: string, data?: any) {
        Logger.isLogEnabled() && console.log(message, data);
        analyticsLogger.info(message, data);
    }

    public static logAnalytics(level: LogLevel, message: string, value: any) {
        Logger.log(message, value);
        LogsSender.logWithAnalytics({
            route: value?.errorResponse?.config?.url,
            CustomMessage: message,
            Message: value?.message,
            Stack: value?.stack ?? value?.errorResponse?.stack,
            LogLevel: level
        });
    }

}