import './AsyncPageLoading.scss';

import React from 'react';

import { Logo } from '../../Logo/Logo';
import { LoadingPage } from '../LoadingPage/LoadingPage';

export interface AsyncPageLoadingProps {
  error: object | null;
  //* function() => void : Retries loading the component
  retry?: () => void;
  //* If the component import timed out
  timedOut: boolean;
  //* If the loader is past the delay specified
  pastDelay: boolean;
}

export const AsyncPageLoading = ({
  error = null,
  timedOut = false,
  pastDelay = false,
  retry = undefined,
}: AsyncPageLoadingProps) => {
  if (error || timedOut) {
    return (
      <div className="app-async-loading-page">
        <div className="content-container">
          <Logo large className="app-logo" />
        </div>
      </div>
    );
  }

  return <LoadingPage />;
};
