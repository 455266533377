
export enum MeInitiliazedReason {

}

export class MeActions {
    public static SET_INFO = 'app/me/set/info';

    static setInfo = (initialized: boolean, info?: any): SetInfoAction => ({
        type: MeActions.SET_INFO,
        payload: { info, initialized },
    });
}


export interface SetInfoAction {
    type: typeof MeActions.SET_INFO;
    payload: { info?: any; initialized: boolean, reason?: MeInitiliazedReason | string };
}

export type MeAction = SetInfoAction;