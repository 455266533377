import UAParser from 'ua-parser-js';

import uuidv4 from 'uuid/v4';
import { LogMessageDeviceFields } from '../LogMessageModel';

export class DeviceInformations {
    private static DEVICE_ID_KEY: string = 'clicandpose_front_device_id';

    private static parser = new UAParser();

    private static getViewportDimensions(): string {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        return `(${width},${height})`;
    };

    private static getScreenDimensions(): string {
        const width = Math.max(window.screen.width, window.screen.availWidth || 0);
        const height = Math.max(window.screen.height, window.screen.availHeight || 0);

        return `(${width},${height})`;
    };

    private static getDeviceId(): string {
        let deviceId: string | null = localStorage.getItem(DeviceInformations.DEVICE_ID_KEY);
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem(DeviceInformations.DEVICE_ID_KEY, deviceId);
        }

        return deviceId;
    };

    public static getElasticSearchDeviceInfo(): LogMessageDeviceFields {
        const browser = DeviceInformations.parser.getBrowser();
        const os = DeviceInformations.parser.getOS();
        const deviceId: string = DeviceInformations.getDeviceId();

        return {
            DeviceId: deviceId,
            Environment: 'Web',
            LocalizedModel: browser.name ?? 'undefined',
            Model: browser.name ?? 'undefined',
            Display: {
                Size: DeviceInformations.getViewportDimensions(),
                NativeSize: DeviceInformations.getScreenDimensions()
            },
            Browser: {
                Name: browser.name ?? 'undefined',
                Version: browser.version ?? 'undefined'
            },
            Os: {
                Name: os.name ?? 'undefined',
                Version: os.version ?? 'undefined',
            }
        };
    };
}
