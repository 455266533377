import { AuthenticationResult } from "../../../../Models/Authentication/AuthResponse";

export enum AuthActionType {
    SET_INITIALIZED = 'app/auth/base/set/initialized',
    LOG_OUT = 'app/auth/base/logout',
    SET_AUTHENTICATED = 'app/auth/base/set/isAuthenticated',
}


export enum InitializedReason {
    SET_AUTHORIZATION = 'SET_AUTHORIZATION',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    REFRESH_SUCCESS = 'REFRESH_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    REFRESH_FAILED = 'REFRESH_FAILED',
    CLEAR_AUTHORIZATION = 'CLEAR_AUTHORIZATION'
}

export interface LogoutAction {
    type: AuthActionType.LOG_OUT;
    payload: { reason?: string };
}

export interface SetInitializedAction {
    type: AuthActionType.SET_INITIALIZED;
    payload: { initialized: boolean; reason: string };
}

export interface SetAuthenticatedAction {
    type: AuthActionType.SET_AUTHENTICATED;
    payload: {
        authorization: AuthenticationResult;
        isAuthenticated: boolean;
        initialized: boolean;
        initializedReason?: InitializedReason;
    };
}

export type AuthenticationAction =
    | LogoutAction
    | SetAuthenticatedAction;
