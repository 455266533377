import * as analyticsLogger from 'loglevel';
//@ts-ignore
import remote from 'loglevel-plugin-remote';
import { UserInformations } from './Analytics/UserInformations';
import { DeviceInformations } from './Analytics/DeviceInformations';
import { Constants } from '../Constants';
import { LogMessageFields } from './LogMessageModel';
import { LogLevel } from './LogLevel';

export class LogsSender {
    public static getLogHeader(): string {
        return JSON.stringify({
            index: { _index: Constants.LOGS.elasticSearchIndex, _type: 'analytics' },
        });
    }

    public static setupLogsSender(): void {
        analyticsLogger.setLevel(analyticsLogger.levels.INFO);

        if (!Constants.isLocalDev) {
            // disable normal console logging
            //@ts-ignore
            analyticsLogger.methodFactory = () => () => undefined;
        }

        remote.apply(analyticsLogger, {
            url: Constants.LOGS.elasticSearchUrl,
            // we send a string, but it elasticsearch expects NDJSON format
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 7eec1c1f-67ef-4b7c-b295-8b3bc06dc0a5',
            },
            // the body must have a newline at the end, but we cannot control that
            // so just add a newline on every message
            //@ts-ignore
            format: ({ message }) => `${message}\n`,
            capacity: 10,
            level: 'warn', // /!\ not linked with LogLevel inside LogMessageFields
            interval: 1000 * 10, // 10 seconds between each log send to elastic search
        });
    }

    public static logWithAnalytics(props: LogMessageFields = {}): void {
        const message = {
            Fields: {
                ...props,
                AppVersion: Constants.VERSION_NUMBER,
                UserInfo: UserInformations.getUserInfo(),
                Device: DeviceInformations.getElasticSearchDeviceInfo(),
            },
            timestamp: new Date().toISOString(),
            LogLevel: props.LogLevel ?? LogLevel.Information,
        };

        const log = `${LogsSender.getLogHeader()}\n${JSON.stringify(message)}`;

        analyticsLogger.warn(log);
    }
}
