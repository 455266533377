import { AppRoutes } from '../../Core/App/AppRoutes';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { UserListPageLoading } from '../Pages/Users/UserListPage/UserListPageLoading';

export class UserRoutes {
    private static getUserListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.USERS,
            component: UserListPageLoading,
        },
    });

    public static getUserRoutes = () => [UserRoutes.getUserListRoute()];
}
