import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast, ToastId } from 'react-toastify';

import { CustomToast, CustomToastType } from '../../Web/Components/Views/CustomToast/CustomToast';

export interface ToastActionOptions {
    actionTitle: string;
    action?: () => void;
    hideToastActionTrigger?: () => void;
}

export interface DownloadOptions {
    fileName: string;
    content: string;
    success?: boolean;
    failed?: boolean;
}

export interface ToastParams {
    title?: string;
    content: React.ReactNode;
    actionOptions?: ToastActionOptions;
    duration?: number;
}
export class ToastService {
    public static showSuccess(toastParams: ToastParams): ToastId {
        const { title, content } = toastParams;
        return toast.success(CustomToast({ type: CustomToastType.SUCCESS, title, content }));
    }
    public static showError(toastParams: ToastParams): ToastId {
        const { title, content, actionOptions, duration } = toastParams;
        return toast.error(
            CustomToast({
                type: CustomToastType.ERROR,
                title,
                content,
                actionOptions,
            }),
            { autoClose: duration }
        );
    }

    public static showInfo(toastParams: ToastParams): ToastId {
        const { title, content, actionOptions } = toastParams;
        return toast.info(
            CustomToast({ type: CustomToastType.INFO, title, content, actionOptions })
        );
    }

    public static showSuccessUpdate(): ToastId {
        return toast.success(
            CustomToast({
                type: CustomToastType.SUCCESS,
                content: <FormattedMessage id="Toast_GenericSuccessUpdateData" />,
            })
        );
    }

    //* CUSTOM DOWNLOAD TOAST
    public static showDownloadInfo(downloadOptions: DownloadOptions): ToastId {
        return '';
    }

    public static updateDownloadInfo(toastId: ToastId, downloadOptions: DownloadOptions): ToastId {
        return '';
    }

    public static notImplemented(): ToastId {
        return ToastService.showInfo({ content: 'Not implemented yet' });
    }

    public static dismiss(toastId: ToastId): void {
        toast.dismiss(toastId);
    }
}
