import { StoreProvider } from '../../../../Configs/StoreProvider';
import { Messages } from './typings/LocalMessageActionTypes';


export class I18n {

    public static get = (id: string): string => {
        const messages = StoreProvider.getMessages();
        return messages[id] || id;
    };

    public static getMessages = (): Record<string, string> => StoreProvider.getMessages();

    //* placeholders is type of { [key: string]: string }
    public static formatMessage = (id: string, placeholders: Messages): string => {
        const messages: Messages = I18n.getMessages();
        let message = messages[id];
        for (var propertyName in placeholders) {
            var re = new RegExp(`{${propertyName}}`, 'gm');
            message = message.replace(re, placeholders[propertyName]);
        }
        return message;
    };
}