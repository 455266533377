import { NavigationService } from '../../Web/Services/NavigationService';
import { AppActionType } from '../App/AppActions';
import { AuthService } from '../Services/Data/Authentication/AuthenticationService';

import * as AuthenticationTypes from '../Services/Data/Authentication/typings/AuthenticationActionTypes';

export const AuthenticationMiddleware =
    ({ dispatch, getState }: { dispatch: any; getState: any }) =>
        (next: any) =>
            (action: any) => {
                if (action) {
                    switch (action.type) {
                        case AuthenticationTypes.AuthActionType.LOG_OUT:
                            NavigationService.goToLogin();
                            break;
                        case AppActionType.APP_ESSENTIAL_RELOAD:
                            const res = next(action);
                            AuthService.refreshAuthentication().catch((err: any) => err);
                            return res;
                        default:
                            break;
                    }
                }

                return next(action);
            };
