import { StoreProvider } from '../../../../Configs/StoreProvider';
import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { MeDataService } from '../../../DataServices/MeDataService';
import { MeActions } from './MeActions';

const dispatch = (action: any) => StoreProvider.dispatch(action);

export class MeService {

    public static getMyInfo = async (cts?: XHRRequestCanceler): Promise<any> => {
        try {
            await dispatch(MeActions.setInfo(false));

            const response: any = await MeDataService.getMyInfo(cts);
            await dispatch(MeActions.setInfo(true, response.data));

            return Promise.resolve(response);
        } catch (error) {
            await dispatch(MeActions.setInfo(false));
            return Promise.reject(error);
        }
    };
}