import React from 'react';
import { useDispatch } from 'react-redux';

import { AppActions } from '../../../../../Core/App/AppActions';
import { LoadingPage } from '../../Loading/LoadingPage/LoadingPage';

export interface EssentialsActionsGateProps {
    /** Array of functions or promises */
    actions: Array<() => Promise<boolean>>;
    /** If should automatically setup intl & react-intl */
    setupIntl?: () => Promise<boolean>;
    /** If should trigger 'BaseActions.triggerEssentialReload' */
    withEssentialReload: boolean;

    children?: React.ReactNode;
}
/** Executes an array of prommises on mount */
export const EssentialsActionsGate = ({
    children,
    actions = [],
    setupIntl,
    withEssentialReload,
}: EssentialsActionsGateProps) => {
    const [ready, setReady] = React.useState<boolean>(
        !actions.length && !setupIntl && !withEssentialReload
    );

    const dispatch = useDispatch();
    const triggerEssentialReload = (): Promise<boolean> => {
        dispatch(AppActions.getMeInfo());
        return Promise.resolve(true);
    };

    React.useEffect(() => {
        const executeAutoActions = () => {
            let autoActions: Array<() => Promise<boolean>> = [];
            if (setupIntl) {
                autoActions = [...autoActions, setupIntl];
            }
            if (withEssentialReload) {
                autoActions = [...autoActions, triggerEssentialReload];
            }
            const allActions: Array<() => Promise<boolean>> = [...autoActions, ...actions];

            const safeActions = allActions
                //* transform all functions to promises
                .map((action) => action())
                //* catch all errors so that we can execute all actions, even if some previous ones failed
                .map((action) => action.catch((err) => err));

            Promise.all(safeActions).then(() => setReady(true));
        };
        executeAutoActions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <React.Fragment>{ready ? children : <LoadingPage />}</React.Fragment>;
};
