import React from 'react';
import { ReactElement } from 'react';

import { AlertDialog } from '../Components/Views/Dialogs/AlertDialog/AlertDialog';
import { ConfirmDialog } from '../Components/Views/Dialogs/ConfirmDialog/ConfirmDialog';
import { DialogLayoutStyle } from '../Components/Views/Dialogs/DialogLayout/DialogLayout';

export type DialogAction = () => void | Promise<void>;

export enum DialogStyle {
    Default,
    Danger,
}
export interface DialogContainer {
    showDialog: (dialog: React.ReactNode) => void;
    destroyDialog: () => void;
}

let dialogContainer: DialogContainer | null = null;

export class DialogService {
    public static showDialog = (
        title: string,
        content: string,
        leftActionTitle: string,
        rightActionTitle: string,
        leftAction: DialogAction,
        rightAction: DialogAction,
        onClosed: DialogAction,
        style?: DialogStyle
    ): void => {
        this.internalShowDialog(
            <ConfirmDialog
                open={true}
                title={title}
                message={content}
                leftActionLabel={leftActionTitle}
                leftAction={() => this.closeDialog(leftAction)}
                rightActionLabel={rightActionTitle}
                rightAction={() => this.closeDialog(rightAction)}
                onClose={() => this.closeDialog(onClosed)}
                style={
                    style === DialogStyle.Danger
                        ? DialogLayoutStyle.Danger
                        : DialogLayoutStyle.Default
                }
            />
        );
    };

    public static showAlertDialog = (
        title: string,
        content: string,
        actionTitle: string,
        doAction: DialogAction,
        onClosed: DialogAction
    ): void => {
        this.internalShowDialog(
            <AlertDialog
                open={true}
                title={title}
                message={content}
                actionLabel={actionTitle}
                doAction={() => this.closeDialog(doAction)}
                onClose={() => this.closeDialog(onClosed)}
            />
        );
    };

    //#region OPEN AND CLOSE

    public static setDialogContainer = (container: DialogContainer): void => {
        if (dialogContainer) {
            dialogContainer.destroyDialog();
        }
        dialogContainer = container;
    };

    public static internalShowDialog = (content: ReactElement) => {
        if (dialogContainer) {
            dialogContainer.showDialog(content);
        }
    };

    public static closeDialog = (actionToDo?: Function) => {
        if (dialogContainer != null) {
            dialogContainer.destroyDialog();
            actionToDo?.();
        }
    };

    //#endregion
}
