import { AppRoutes } from '../../Core/App/AppRoutes';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { DoormatPageLoading } from '../Pages/Doormats/DoormatPage/DoormatPageLoading';

export class DoormatRoutes {
    private static getDoormatRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.DOORMATS_DETAILS,
            component: DoormatPageLoading,
        },
    });

    public static getDoormatRoutes = () => [DoormatRoutes.getDoormatRoute()];
}
