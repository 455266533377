import { AppRoutes } from '../Core/App/AppRoutes';
import { RouteItem } from '../Web/Components/Templates/AppRouter/RouteItem';
import { AuthRoutes } from '../Web/Routes/AuthRoutes';
import { VersionParametrageRoutes } from '../Web/Routes/VersionParametrageRoutes';

import { CarpetRoutes } from '../Web/Routes/CarpetRoutes';
import { EscalierRoutes } from '../Web/Routes/EscalierRoutes';
import { DoormatRoutes } from '../Web/Routes/DoormatRoutes';
import { UserRoutes } from '../Web/Routes/UserRoutes';

export class CSMRoutes {
    public static DEFAULT_ROUTE = AppRoutes.HOME;

    public static getAppRoutes = (): Array<RouteItem> => [
        ...AuthRoutes.getAuthRoutes(),
        ...VersionParametrageRoutes.getVersionParametrageRoutes(),
        ...CarpetRoutes.getCarpetRoutes(),
        ...EscalierRoutes.getEscalierRoutes(),
        ...DoormatRoutes.getDoormatRoutes(),
        ...UserRoutes.getUserRoutes(),
    ];
}