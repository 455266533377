import { XHRRequestConfig } from '../../Libs/xhr/XHRRequest';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiRequest } from '../../Services/Api/ApiRequest';
import { ApiUrls } from '../Models/ApiUrls';


export class MeDataService {
    public static getMyInfo = (cts?: XHRRequestCanceler, config?: XHRRequestConfig): Promise<any> => {
        return ApiRequest.get<any>(ApiUrls.ME, config);
    };
}