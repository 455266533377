import "./LoadingPage.scss";

import React from "react";

import { CustomLoader } from "../../CustomLoader/CustomLoader";
import { Logo } from "../../Logo/Logo";

export interface LoadingPageProps {}
// eslint-disable-next-line no-empty-pattern
export const LoadingPage: React.FC<LoadingPageProps> = ({}: LoadingPageProps) => {
  return (
    <div className="LoadingPage-root">
      <div className="LoadingPage-content">
        <Logo large className="LoadingPage-logo" />
        <CustomLoader />
      </div>
    </div>
  );
};
