import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme, ThemeProvider as MuiThemeProviderOrigin } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { fr as frLocale } from 'date-fns/locale';
import React from 'react';

export interface MuiAppProps {
  children?: React.ReactNode;
  //* Mui Theme
  getMuiTheme: () => Theme;
}

const MuiApp = ({ getMuiTheme, children }: MuiAppProps) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <MuiThemeProviderOrigin theme={getMuiTheme()}>
          {children}
        </MuiThemeProviderOrigin>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

export { MuiApp };
