import { AppState } from '../../../Configs/CsmStore';
import { StoreProvider } from '../../../Configs/StoreProvider';
import { LogMessageUserFields } from '../LogMessageModel';


export class UserInformations {
    public static getUserInfo(): LogMessageUserFields {
        const state: AppState = StoreProvider.getState();
        const meData = state.Me.data;

        const loggedUser = meData?.stmac_store_properties ?? meData?.back_office_user_properties ?? meData?.customer_properties;

        let uid: string | undefined = undefined;
        let type: string | undefined = undefined;
        switch (loggedUser?.constructor?.name) {
            case 'CustomerPropertiesDto':
                uid = loggedUser.id;
                type = 'Customer';
                break;
            case 'StmacStorePropertiesDto':
                uid = loggedUser.id;
                type = 'StmacStore';
                break;
            case 'BackOfficeUserPropertiesDto':
                uid = loggedUser.id;
                type = 'BackOfficeUser';
                break;

            default:
                break;
        }

        return {
            Id: uid,
            Type: type
        };
    }
};