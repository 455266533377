import './ConfirmDialog.scss';

import React from 'react';

import { DialogLayout, DialogLayoutProps } from '../DialogLayout/DialogLayout';

interface ConfirmDialogProps extends DialogLayoutProps {
    message: React.ReactNode;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
    message,
    ...rest
}: ConfirmDialogProps) => {
    return (
        <DialogLayout
            {...rest}
            renderContent={() => <div className="confirm-dialog-content">{message}</div>}
        />
    );
};

export { ConfirmDialog };
