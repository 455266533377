import './CustomButton.scss';

import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { CustomLoader } from '../../Templates/CustomLoader/CustomLoader';

export enum ButtonClassType {
    Standard = 'Button-standard',
    Danger = 'Button-danger',
    Success = 'Button-success',
    Light = 'Button-light',
    Dark = 'Button-dark',
    Link = 'Button-link',
    Bordered = 'Button-bordered',
    Confirm = 'Button-confirm',
    Grey = 'Button-grey',
}

export interface CustomButtonProps {
    icon?: React.ReactElement;
    endRowIcon?: React.ReactElement;
    title?: React.ReactNode;
    variant?: ButtonClassType;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    isBusy?: boolean;
    link?: string;
    visible?: boolean;
    fullWidth?: boolean;
    classes?: { root?: string; label?: string; content?: string; icon?: string };
    ref?: any;
}

export const CustomButton: React.FunctionComponent<CustomButtonProps> = ({
    title = '',
    icon,
    endRowIcon,
    variant = ButtonClassType.Standard,
    onClick,
    disabled = false,
    type,
    isBusy,
    link,
    visible = true,
    fullWidth,
    classes,
    ref,
}: CustomButtonProps) => {
    if (!visible) {
        return null;
    }

    //#region  //* CLASSES
    const {
        root: rootClasses,
        label: labelClasses,
        content: contentClasses,
        icon: iconClasses,
    } = classes ?? {
        root: '',
        label: '',
        content: '',
        icon: '',
    };

    const enhancedRootClasses = classNames({
        'CustomButton-root': true,
        disabled,
        [rootClasses || '']: rootClasses,
        fullWidth,
    });

    const enhancedButtonClasses = classNames({
        'CustomButton-button': true,
        disabled,
        fullWidth,
    });

    const enhancedButtonLabelClasses = classNames({
        'CustomButton-button-label': true,
        [labelClasses || '']: labelClasses,
        disabled,
    });

    const enhancedLinkButtonClasses = classNames({
        'CustomButton-button-link': true,
        disabled,
    });

    const enhancedButtonContentClasses = classNames({
        'CustomButton-content': true,
        disabled,
        [variant]: variant,
        [contentClasses || '']: contentClasses,
        'CustomButton-content-busy': isBusy,
        'CustomButton-content-fullWidth': fullWidth,
        withIcon: icon,
    });

    const enhancedButtonContentIconClasses = classNames({
        'CustomButton-content-icon': true,
        [iconClasses || '']: iconClasses,
        disabled,
    });

    const enhancedButtonContentEndIconClasses = classNames({
        'CustomButton-content-end-icon': true,
        disabled,
    });

    //#endregion

    const getContent = (): React.ReactNode => (
        <div className={enhancedButtonContentClasses}>
            {icon && !isBusy && <div className={enhancedButtonContentIconClasses}>{icon}</div>}
            {isBusy ? <CustomLoader /> : <Typography>{title}</Typography>}
            {endRowIcon && !isBusy && (
                <div className={enhancedButtonContentEndIconClasses}>{endRowIcon}</div>
            )}
        </div>
    );

    return (
        <div className={enhancedRootClasses}>
            <Button
                ref={ref}
                onClick={onClick}
                disabled={disabled || isBusy}
                type={type}
                classes={{
                    root: enhancedButtonClasses,
                    label: enhancedButtonLabelClasses,
                }}
            >
                {link ? (
                    <Link to={link} className={enhancedLinkButtonClasses}>
                        {getContent()}
                    </Link>
                ) : (
                    getContent()
                )}
            </Button>
        </div>
    );
};
