import { AppState } from '../../../../Configs/CsmStore';
import { StoreProvider } from '../../../../Configs/StoreProvider';
import {
    AuthDataService,
} from '../../../DataServices/AuthenticationDataService';
import { handleError } from '../../../Errors/CSMErrorHandler';

import { ApiResponse } from '../../../Models/ApiData';
import {
    AuthenticationResponse,
    AuthenticationResult,
    PostFrontTokenDto,
} from '../../../Models/Authentication/AuthResponse';
import { ApiErrorCodes } from '../../../Models/ErrorCodes';
import { AuthenticationActions, EmailActions } from './AuthenticationActions';
import { InitializedReason } from './typings/AuthenticationActionTypes';
import { AuthUtils } from './utils/AuthUtils';

export enum AuthenticateResultType {
    SUCCESS = 'SUCCESS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    LOGIN_ERROR = 'LOGIN_ERROR',
}


const dispatch = (action: any) => StoreProvider.dispatch(action);
const getState = (): AppState => StoreProvider.getState();

export class AuthService {

    public static login = async (
        email: string,
        password: string,
    ): Promise<AuthenticateResultType> => {
        try {
            const response: AuthenticationResponse = await AuthDataService.login(email, password);
            const transformedAuth = AuthUtils.toAuthWithExpirationDate(
                response.data as AuthenticationResult
            );

            await dispatch(AuthenticationActions.loginSuccess(transformedAuth));
            await dispatch(EmailActions.loginEmailSuccess(transformedAuth));

            return Promise.resolve(AuthenticateResultType.SUCCESS);
        } catch (error) {
            const { error_message }: ApiResponse = error as any;
            dispatch(AuthenticationActions.loginFail(error));
            dispatch(EmailActions.loginEmailFailure(error));

            if (error_message === ApiErrorCodes.INVALID_CREDENTIALS_MESSAGE) {
                return Promise.resolve(AuthenticateResultType.INVALID_CREDENTIALS);
            } else {
                return Promise.resolve(AuthenticateResultType.LOGIN_ERROR);
            }
        }
    };

    public static logout = (reason?: string) => {
        return dispatch(AuthenticationActions.logout(reason));
    };

    public static forgotten = async (email: string): Promise<ApiResponse> => {
        try {
            const response: ApiResponse = await AuthDataService.forgotten(email);
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static reset = async (
        email: string,
        new_password: string,
        validation_code: string
    ): Promise<AuthenticationResponse> => {
        try {
            const response: AuthenticationResponse = await AuthDataService
                .reset(validation_code, email, new_password);
            const transformedAuth = AuthUtils.toAuthWithExpirationDate(
                response.data as AuthenticationResult
            );

            await dispatch(AuthenticationActions.loginSuccess(transformedAuth));
            await dispatch(EmailActions.loginEmailSuccess(transformedAuth));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    //? @returns {String} the authorization header, NOT the token
    public static getAuthorization = async (): Promise<string> => {
        const { token_type, access_token } = getState().Authentication;

        const auth = AuthUtils.getAuthorizationFromToken(token_type, access_token);
        return Promise.resolve(auth);
    };

    /**
     * If the access token is not expired, fires refreshSuccess right away with current state.
     * Resets the initialized state (keeps the authenticated state) and then refreshes the access token
     * @param {Boolean} forceRefresh - If should refresh the tokens, even if the current ones are not expired
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
     * with props `isError` and `err`
     * @throws {Promise<Error>} an Axios error if the refresh failed
     */
    public static refreshAuthentication = async (): Promise<string> => {
        const authentication = getState().Authentication;

        const { refresh_token } = authentication || { refresh_token: '' };

        if (!refresh_token) {
            const errorMessage = 'No refresh token found, cannot refresh and will logout';
            handleError(errorMessage);
            dispatch(AuthenticationActions.refreshFail(errorMessage));
            dispatch(EmailActions.refreshEmailFailure(errorMessage));

            return Promise.reject(errorMessage);
        } else {
            dispatch(AuthenticationActions.setInitialized(false, 'loading refresh'));
            try {
                const response: AuthenticationResponse = await AuthDataService.refresh(refresh_token);

                dispatch(AuthenticationActions.refreshSuccess(response.data));
                dispatch(EmailActions.refreshEmailSuccess(response.data));

                const authorization = AuthUtils.getAuthorizationFromToken(
                    response.data.token_type,
                    response.data.access_token
                );
                return Promise.resolve(authorization);
            } catch (error) {
                dispatch(AuthenticationActions.refreshFail(error));
                dispatch(EmailActions.refreshEmailFailure(error));
                return Promise.reject(error);
            }
        }
    };

    public static async setAuthorizationToken(authorizationToken: AuthenticationResult): Promise<void> {
        await dispatch(
            AuthenticationActions.setAuthorization(
                authorizationToken,
                InitializedReason.SET_AUTHORIZATION
            )
        );
    }

    public static async removeAuthorizationToken(): Promise<void> {
        await dispatch(AuthenticationActions.clearAuthorizationHeader());
    }

    public static getFrontToken(bodyRequest: PostFrontTokenDto): Promise<AuthenticationResponse> {
        return AuthDataService.getFrontToken(bodyRequest);
    }
}

