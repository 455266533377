import { Messages } from '../Services/Data/I18n/typings/LocalMessageActionTypes';

export class AppRoutes {
    //* Auth
    public static LOGIN = '/login';
    public static FORGOT_PASSWORD = '/password/forgot';
    public static RESET_PASSWORD = '/passwords/reset/:email/:token';
    public static INIT_PASSWORD = '/passwords/initialize/:email/:token';
    public static NEW_PASSWORD = '/password/new';

    //* VERSIONS
    public static VERSION_CARPET_LIST = '/versions/tapis';
    public static VERSION_ESCALIERS_LIST = '/versions/escaliers';
    public static VERSION_DOORMATS_LIST = '/versions/doormats';

    //* TAPIS
    public static CARPET = '/tapis';
    public static CARPET_DETAILS = `${AppRoutes.CARPET}/:id`;

    public static VALEUR_LIST = '#valeurs';
    public static TYPE_FINITION_LIST = '#types-finition';
    public static MATIERE_FINITION_LIST = '#matieres-finition';
    public static FINITION_LIST = '#finitions';
    public static TAPIS_DE_BASE_LIST = '#tapis-de-base';
    public static MARCHE_LIST = '#marches';
    public static CONTREMARCHE_LIST = '#contremarches';
    public static GAMME_LIST = '#gammes';
    public static REFERENCE_LIST = '#references';

    public static CARPET_HASHES = [
        AppRoutes.VALEUR_LIST,
        AppRoutes.TYPE_FINITION_LIST,
        AppRoutes.MATIERE_FINITION_LIST,
        AppRoutes.FINITION_LIST,
        AppRoutes.TAPIS_DE_BASE_LIST,
    ];

    //* ESCALIER
    public static ESCALIERS = '/escaliers';
    public static ESCALIERS_DETAILS = `${AppRoutes.ESCALIERS}/:id`;
    public static ESCALIERS_HASHES = [
        AppRoutes.VALEUR_LIST,
        AppRoutes.MARCHE_LIST,
        AppRoutes.CONTREMARCHE_LIST,
    ];

    //* DOORMAT
    public static DOORMATS = '/doormats';
    public static DOORMATS_DETAILS = `${AppRoutes.DOORMATS}/:id`;
    public static DOORMATS_HASHES = [
        AppRoutes.VALEUR_LIST,
        AppRoutes.GAMME_LIST,
        AppRoutes.REFERENCE_LIST,
    ];

    //* USERS
    public static USERS = '/users';

    public static HOME = AppRoutes.VERSION_CARPET_LIST;

    public static toCarpetVersionRoute = (versionId: string): string => {
        return AppRoutesHelper.toDetailsRoute('id', versionId, AppRoutes.CARPET_DETAILS);
    };

    public static toEscalierVersionRoute = (versionId: string): string => {
        return AppRoutesHelper.toDetailsRoute('id', versionId, AppRoutes.ESCALIERS_DETAILS);
    };

    public static toDoormatVersionRoute = (versionId: string): string => {
        return AppRoutesHelper.toDetailsRoute('id', versionId, AppRoutes.DOORMATS_DETAILS);
    };
}

export class AppRoutesHelper {
    public static formatUrl(url: string, placeholders: Messages): string {
        let newUrl: string = url;
        for (var propertyName in placeholders) {
            var re = new RegExp(`:${propertyName}`, 'gm');
            newUrl = newUrl.replace(re, placeholders[propertyName]);
        }
        return newUrl;
    }

    public static toDetailsRoute = (
        paramKey: string,
        paramValue: string,
        detailsRoute: string
    ): string => `${detailsRoute}`.replace(`:${paramKey}`, paramValue);
}
