import { Action, LocationState } from 'history';

import { browserHistory as history } from '../../Configs/browserHistory';
import { AppRoutes } from '../../Core/App/AppRoutes';
import { VersionType } from '../../Core/Models/Versions/VersionDto';

export interface GoToRouteParameter {
    readonly route: string;
    readonly replace?: boolean;
    readonly state?: any;
    readonly search?: string;
}
export class NavigationService {

    public static initialize(): void {
        history.listen((location: LocationState, action: Action) => {
            console.log('history listen', { location, action });
            //* can be used to reset ViewModel if url changed
        });
    }

    public static goTo = (goToRouteParameter: GoToRouteParameter): void => {
        const { route, replace, state, search } = goToRouteParameter;
        if (replace) {
            history.replace({ pathname: route, state, search });
        } else {
            history.push({ pathname: route, state, search });
        }
    };

    public static replace = (newUrl: string): void => history.replace({ pathname: newUrl });

    public static replaceParams = (queryParams: URLSearchParams): void => {
        history.push({ search: queryParams?.toString() });
    };

    public static goBack = () => history.goBack();

    public static getHomeLink = AppRoutes.HOME;
    public static goToHome = (): void => NavigationService.goTo({ route: AppRoutes.HOME });
    public static goToLogin = (): void => NavigationService.goTo({ route: AppRoutes.LOGIN });

    //* versions
    public static goToVersions = (versionType: VersionType): void => {
        switch (versionType) {
            case VersionType.Carpets:
            default:
                NavigationService.goTo({ route: AppRoutes.VERSION_CARPET_LIST });
                break;
            case VersionType.Escaliers:
                NavigationService.goTo({ route: AppRoutes.VERSION_ESCALIERS_LIST });
                break;
        }
    };
}
