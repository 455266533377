import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { useCacheForceReloadIfNeeded } from '../../../../Services/CacheService';

import { RouteItem } from './RouteItem';

export interface AppRouterProps {
    routerHistory: any;
    routes: Array<RouteItem>;
    defaultRoute: string;
}

export const AppRouter: React.FC<AppRouterProps> = ({
    routerHistory,
    defaultRoute,
    routes,
}: AppRouterProps) => {
    return (
        <ConnectedRouter history={routerHistory}>
            <CacheManager>
                <Switch>
                    {routes.map(({ render: Render, props }: RouteItem) => {
                        const { exact = true } = props;
                        return (
                            <Render
                                key={`${props.path}_${exact ? 'exact' : ''}`}
                                {...props}
                                exact={exact}
                            />
                        );
                    })}
                    <Redirect to={defaultRoute} />
                </Switch>
            </CacheManager>
        </ConnectedRouter>
    );
};

const CacheManager = ({ children }: React.PropsWithChildren<{}>) => {
    const location = useLocation();
    useCacheForceReloadIfNeeded(location.pathname);
    return <>{children}</>;
};
