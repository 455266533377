import { LogLevel } from '../LogsSender/LogLevel';
import { handleError } from './CSMErrorHandler';
import { Logger } from './Logger';

export const wrapError = async <TResult>(
    execAction: () => Promise<TResult>,
    execParams?: { errorHandler?: (error: any) => Promise<boolean>; }
): Promise<TResult | any> => {
    const { errorHandler } = execParams ?? { customErrorHandler: undefined };

    try {
        const result: TResult = await execAction();
        return result;
    } catch (error) {
        //* error CAN BE : RESULT of onRequestFailed
        const errorHandled: boolean = (await errorHandler?.(error)) ?? false;
        Logger.logAnalytics(LogLevel.Error, '🙀🙀🙀🙀🙀  | wrapError', { error, errorHandled });
        if (!errorHandled && (await !handleError(error))) {
            throw error;
        }
    }
}