//🇫🇷
export const fr = {
    ApplicationName: 'CSM',
    ApplicationNameFormatted: 'CSM',

    //* COMMON
    COMMON_SAVE: 'Enregistrer',
    COMMON_CONTINUE: 'Continuer',
    COMMON_CANCEL: 'Annuler',
    COMMON_CREATE: 'Créer',
    COMMON_IMPORT: 'Importer',
    COMMON_EDIT: 'Éditer',
    COMMON_YES: 'Oui',
    COMMON_NO: 'Non',
    COMMON_OK: 'OK',
    COMMON_VALIDATE: 'Valider',
    COMMON_DELETE: 'Supprimer',
    COMMON_CLEAR: 'Effacer',
    COMMON_DATE_DEFAULT_VALUE: '?',
    COMMON_USER_FULLNAME: '{firstName} {lastName}',
    COMMON_WARNING: 'Attention',
    COMMON_BAD_VALUE_FIELD: 'Champ erroné',
    COMMON_EMPTY_MESSAGE: 'Aucun résultat',
    COMMON_REQUIRED_FIELD: 'Champ obligatoire*',
    COMMON_SEARCH: 'Rechercher',
    COMMON_TEST: 'Tester',
    COMMON_EXPORT: 'Exporter',
    COMMON_FILTER_ALL: 'Toutes',
    COMMON_ERROR_TITLE: 'Erreur : {code}',
    COMMON_ERROR_MESSAGE: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.",

    //* ERRORS
    ERROR_BadCredentials: 'Identifiants invalides',
    ERROR_InvalidEmail: 'Adresse email invalide',
    ERROR_WeakPassword:
        "Votre mot de passe est trop faible, veillez à ce qu'il soit composé d'au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
    ERROR_NotFound: "L'élément est introuvable",
    ERROR_REFERENCING_MATIERE_FINITION_FORBIDS_DELETION:
        'Au moins une matière de finition référence ce type de finition, il ne peut donc pas être supprimé.',
    ERROR_REFERENCING_FINITION_FORBIDS_DELETION:
        'Au moins une finition référence cette matière de finition, elle ne peut donc pas être supprimée.',

    ERROR_ProductFound: 'Le produit est introuvable',
    ERROR_UploadImage_InvalidFormat: "Format d'image invalide",

    //* TOAST
    Toast_TitleSuccess: 'Bravo',
    Toast_TitleError: 'Erreur',
    Toast_TitleInfo: 'Info',
    Toast_GenericSuccessUpdateData: 'Les données ont été mises à jour',
    Toast_GenericSuccessDeleteData: 'Les données ont été supprimées',

    //* UploadImage
    UploadImage_InvalidFormat: "Format d'image invalide",
    UploadImage_DefaultTitle: 'Ajouter une image',

    //* Auth
    Auth_Welcome: 'Bienvenue',
    Auth_Text: "sur la console d'administration du configurateur {name}.",

    Login_title: 'Connexion',
    Login_helpText: 'Merci de vous connecter avec vos identifiants.',
    Login_Email: 'Adresse email',
    Login_Password: 'Mot de passe',
    Login_ForgotPassword: 'Mot de passe oublié ?',
    Login_Connect: 'Se connecter',
    INVALID_CLIENT_MESSAGE: 'Les identifiants sont invalides',

    //* DIALOG
    Dialog_LogoutTitle: 'Déconnexion',
    Dialog_LogoutMessage: 'Êtes-vous sur de vouloir vous déconnecter ?',

    //* FORGOT PASSWORD
    ForgotPassword_title: 'Mot de passe oublié',
    ForgotPassword_helpText:
        'Merci de renseigner votre adresse mail afin de réinitialiser votre mot de passe.',
    ForgotPassword_Email: 'Email',
    ForgotPassword_Send: 'Envoyer',
    ForgotPassword_Back: '< Retour à la connexion',
    ForgotPassword_EmailSent: "L'email a été envoyé avec succès",

    //* RESET PASSWORD
    ResetPassword_title: 'Nouveau mot de passe',
    ResetPassword_helpText: 'Merci de saisir votre nouveau mot de passe',
    ResetPassword_Password: 'Mot de passe',
    ResetPassword_PasswordConfirm: 'Confirmation de mot de passe',
    ResetPassword_Send: 'Envoyer',
    ResetPassword_Back: '< Retour à la connexion',
    ResetPassword_MustBeIdenticals: 'Les mots de passes doivent être identiques',
    ResetPassword_Success: 'Le mot de passe a été mis à jour avec succès',

    //! COMPONENTS
    //* Header
    Header_Logout: 'Se déconnecter',
    Header_Version: '{environment} - {version_number}',
    Header_Environment_prod: 'production',
    Header_Environment_beta: 'beta',
    Header_Environment_test: 'test',
    Header_Environment_dev: 'development',
    Header_Environment_preprod: 'pré-production',
    Header_Environment_localdev: 'local development',

    //* Main Navbar
    Navbar_Carpet: 'Tapis',
    Navbar_Escaliers: 'Réno escalier',
    Navbar_Doormats: 'Tapis de propreté',
    Navbar_Users: 'Administrateurs',

    //* Left NavBar (onglet)
    Navbar_PublishedVersion: 'Versions paramétrage',
    Navbar_VersionsParametrage: 'Versions paramétrage',
    Navbar_TestButtonText: 'Tester le paramétrage',

    //* VERSIONS
    Version_State_Publiee: 'Publiée',
    Version_State_Obsolete: 'Obsolète',
    Version_State_Brouillon: 'Brouillon',

    //* VERSION LIST
    VersionListPage_Subtitle: 'Versions de paramétrage',
    VersionList_TableHeaderLabel: 'Libellé de la version',
    VersionList_TableHeaderCreator: 'Créateur',
    VersionList_TableHeaderCreatedDate: 'Date de création',
    VersionList_TableHeaderQuestionCount: 'Nb de questions',
    VersionList_TableHeaderState: 'État',
    VersionList_PublishedVersion_Title: 'Version publiée',
    VersionList_PreviousVersions_Title: 'Historique des versions',
    VersionList_NewVersionDialog_Title: 'Nouvelle version',
    VersionList_NewVersionDialog_NewVersionLabelTitle: 'Libellé de la nouvelle version*',
    VersionList_Toast_CreateSuccess: 'La version a été créée avec succès',
    VersionList_ImportVersionDialog_Title: 'Importer une version',
    VersionList_ImportVersionDialog_Button: 'Choisissez un fichier',

    //* VERSION DETAILS
    VersionsDetails_PageTitle: 'Version de paramétrage',
    VersionDetails_VersionLabelTitle: 'Libellé de la version',
    VersionDetails_DuplicateVersionLabelTitle: 'Libellé de la nouvelle version*',
    VersionDetails_CommentTitle: 'Commentaire',
    VersionDetails_DialogTitle: 'Version',
    VersionDetails_PublishBtnLabel: 'Publier',
    VersionDetails_DuplicateBtnLabel: 'Dupliquer',
    VersionDetails_TestStoreBtnLabel: 'Test vendeur',
    VersionDetails_TestUserBtnLabel: 'Test client',
    VersionDetails_DeleteBtnLabel: 'Supprimer',
    VersionDetails_Action_Created: 'Créée le :',
    VersionDetails_Action_Published: 'Publiée le :',
    VersionDetails_Action_Obsoleted: 'Obsolète le :',
    VersionDetails_Action_By: 'par',
    VersionDetails_DuplicationError_ReferencingQuestion:
        'Nom de la question référençant la question supprimée : "{bo_label}" ; index : {index}',
    VersionDetails_DuplicationError_ReferencingPrix:
        "Nom de l'élément de prix référençant la question supprimée : '{title}'",
    VersionDetails_DuplicationError_ReferencedQuestion:
        'Nom de la question référencée supprimée : "{bo_label}" ; index : {index}',
    VersionDetails_ConfirmDialogTitle: 'Quitter ?',
    VersionDetails_ConfirmDialogLeftButton: 'Quitter',
    VersionDetails_ConfirmDialogRightButton: 'Annuler',
    VersionDetails_ConfirmDialogMessage:
        'Vous avez des modifications en cours, voulez vous vraiment quitter ?',
    VersionDetails_DeleteTitle: 'Supprimer la version',
    VersionDetails_DeleteContent: 'Êtes vous sur de vouloir supprimer cette version ?',
    VersionDetails_PublishTitle: 'Publier la version',
    VersionDetails_PublishContent: 'Êtes vous sur de vouloir publier cette version ?',
    VersionDetails_Toast_PublishSuccess: 'La version a été publiée avec succès',
    VersionDetails_Toast_DeleteSuccess: 'La version a été supprimée avec succès',
    VersionDetails_Toast_DuplicateSuccess: 'La version a été dupliquée avec succès',
    VersionDetails_Toast_ImportSuccess: 'La version a été importée avec succès',
    VersionDetails_Toast_ImportNoFile: 'Veuillez sélectionner un fichier',

    //* VERSION TEST
    VersionTestTextField_Label: 'Référence Tapis',

    //* VERSION TAPIS
    VersionCarpetListPage_Title: 'Tapis',

    //* VERSION ESCALIERS
    VersionEscalierListPage_Title: 'Escaliers',

    //* VERSION DOORMATS
    VersionDoormatListPage_Title: 'Tapis de propreté',

    //* USERS
    UserListPage_Title: 'Administrateurs',

    //* USERS LIST
    UsersList_TableHeaderName: 'Informations utilisateur',
    UsersList_TableHeaderEmail: 'Email',
    UsersList_AddButtonTitle: 'Ajouter un utilisateur',
    UsersList_SearchPlaceHolder: 'Rechercher un utilisateur',

    //* USERS DETAILS
    UsersDetails_DialogTitle: "Informations d'utilisateur",
    UsersDetails_LastName: 'Nom*',
    UsersDetails_FirstName: 'Prénom*',
    UsersDetails_Code: 'Code',
    UsersDetails_Mail: 'Adresse email*',
    UsersDetails_SendMail: 'Envoyer un email d’invitation',
    UsersDetails_Delete: "Supprimer l'utilisateur",
    UserDetails_QuitTitle: 'Quitter ?',
    UserDetails_QuitTitleContent:
        'Vous avez des modifications non enregistrées, voulez vous vraiment quitter ?',
    UserDetails_MailSuccessfullySent: "L'email a été envoyé avec succès",
    UserDetails_Delete: 'Supprimer ?',
    UserDetails_DeleteContent: 'Voulez vous vraiment supprimer cet utilisateur ?',
    UserDetails_SuccessEdit: "L'utilisateur a été édité avec succès",
    UserDetails_SuccessCreate: "L'utilisateur a été créé avec succès",
    UserDetails_SuccessDelete: "L'utilisateur a été supprimé avec succès",

    //* ESCALIERS
    EscalierPage_Title: 'Escalier',
    EscalierPage_Previous: 'Escaliers',
    Escalier_Menu_Marches: 'Marches',
    Escalier_Menu_Contremarches: 'Contremarches',

    Escalier_RefProduitPrincipal: 'Réf. principale',
    Escalier_Color: 'Coloris WEB',
    Escalier_Status: 'Statut',
    Escalier_Status_Actif: 'Actif',
    Escalier_Status_Inactif: 'Inactif',
    Escalier_Disponibilite: 'Eligibilité',
    Escalier_Disponibilite_Indisponible: 'Non proposé',
    Escalier_Disponibilite_Vendeur: 'Vendeur',
    Escalier_Disponibilite_VendeurClient: 'Vendeur et client',

    EscalierMarcheDialog_Color_Format: 'Coloris WEB : {value}',
    EscalierMarcheDialog_Disponibilite_Title: 'Disponibilité',
    EscalierMarcheDialog_Ref_Title_Marche: 'Référence produit de base (marche en 30x100cm)',
    EscalierMarcheDialog_Ref_Title_Contremarche: 'Référence produit de base',
    EscalierMarcheDialog_Image_Title: 'Ajouter un image',

    ProductFlux_Title: 'Information du flux produit',
    ProductFluxRow_Ref_Title: 'Ref :',
    ProductFluxRow_Product_Label_Title: 'Libellé produit :',
    ProductFluxRow_Status_Title: 'Statut :',
    ProductFluxRow_Price_Title: 'Prix :',
    ProductFluxRow_Price_Format: '{value} TTC',

    Escalier_Error_InvalidRefProduit: 'La réference du produit de base est invalide',

    //* ESCALIERS/MARCHES

    Marches_Title: 'Marches',
    Marches_AddNewMarche: 'Ajouter une marche',
    MarcheDialog_Title: 'Marche',
    Marche_Create_Success: 'La marche a été créée avec succès',
    Marche_DeleteTitle: 'Supprimer la marche',
    Marche_DeleteTitleContent: 'Êtes-vous sûr de vouloir supprimer cette marche ?',
    Marche_Delete_Success: 'La marche a été supprimée avec succès',

    //* ESCALIERS/CONTREMARCHES

    Contremarches_Title: 'Contremarches',
    Contremarches_AddNewContremarche: 'Ajouter une contremarche',
    ContremarcheDialog_Title: 'Contremarche',
    Contremarche_Create_Success: 'La contremarche a été créée avec succès',
    Contremarche_DeleteTitle: 'Supprimer la contremarche',
    Contremarche_DeleteTitleContent: 'Êtes-vous sûr de vouloir supprimer cette contremarche ?',
    Contremarche_Delete_Success: 'La contremarche a été supprimée avec succès',

    //* Carpet
    CarpetPage_Title: 'Tapis',
    CarpetPage_Previous: 'Tapis',
    Carpet_Menu_TypesFinition: 'Types finition',
    Carpet_Menu_MatieresFinition: 'Matières finition',
    Carpet_Menu_Finitions: 'Finitions',
    Carpet_Menu_TapisDeBase: 'Tapis de base',

    //* DOORMATS
    Doormat_Menu_Gammes: 'Gammes',
    Doormat_Menu_References: 'Références',
    DoormatPage_Previous: 'Tapis de propreté',

    //* DOORMATS/GAMMES
    Gammes_PageTitle: 'Gammes',
    Gamme_Title: 'Titre',
    Gammes_AddButtonTitle: 'Ajouter une gamme',
    Gammes_Dialog_Title: 'Gamme',
    Gammes_Dialog_TitleField: 'Titre de la gamme*',
    Gammes_Dialog_LabelField: 'Libellé de la gamme*',
    Gammes_Dialog_DescriptionField: 'Descriptif',
    Gammes_Dialog_HelpField: 'Aide',
    Gammes_Dialog_RefProduitField: 'QCT du produit de référence (pour le prix et la liaison)*',
    Gammes_Dialog_Delete: 'Supprimer la gamme',
    Gamme_DeleteTitle: 'Supprimer la gamme',
    Gamme_DeleteTitleContent: 'Êtes-vous sûr de vouloir supprimer cette gamme ?',
    Gamme_SuccessDelete: 'La gamme a été supprimée avec succès',
    Gamme_SuccessCreate: 'La gamme a été créée avec succès',
    Gamme_SuccessUpdate: 'La gamme a été mise à jour avec succès',
    Gamme_Error_InvalidRefProduit: 'Ce QCT est invalide',

    //* DOORMATS/REFERENCES
    References_Header_Title: 'Références',
    References_RefProduit: 'Référence',
    References_LabelProduit: 'Libellé produit',
    References_GammeAttribute: 'Attribut gamme',
    References_Status: 'Statut',
    References_Status_Actif: 'Actif',
    References_Status_Inactif: 'Inactif',
    References_Disponibility: 'Disponibilité',
    References_Disponibility_Indisponible: 'Non proposé',
    References_Disponibility_Vendeur: 'Vendeur',
    References_Disponibility_VendeurClient: 'Vendeur et client',
    References_Dialog_Title: 'Référence',
    References_Dialog_Produit_Title: 'Informations du flux produit',
    References_Dialog_Produit_Ref: 'Réf',
    References_Dialog_Produit_Price: 'Prix',
    References_Dialog_Produit_GammeAttribute: 'Attribut gamme',
    References_Dialog_Produit_ColorGroup: 'Groupe de couleur',
    References_Dialog_Produit_Reference: 'Référence',
    References_Dialog_Image: 'Image',
    References_SuccessUpdate: 'La référence a été mise à jour avec succès',
    References_SearchPlaceHolder: 'Rechercher une référence',
    References_Header_GammeTitle: 'Gamme :',
    References_Header_GammeSelect_EmptyTitle: 'Non lié',

    //* VALEURS
    Menu_Valeurs: 'Valeurs',
    Valeurs_Title: 'Valeurs',
    Search_ByKey: 'Rechercher une clé',

    Valeurs_Type: 'Type',
    Valeurs_Rubrique: 'Rubrique',
    Valeurs_Key: 'Clé',
    Valeurs_Value: 'Valeur',

    Valeurs_Type_String: 'Libellé',
    Valeurs_Type_Image: 'Image',
    Valeurs_Type_Help: 'Aide',
    Valeurs_Type_Number: 'Nombre',
    Valeurs_Type_Price: 'Prix',

    //* FINITIONS TYPES
    FinitionTypes_PageTitle: 'Types de finition',
    FinitionTypes_AddButtonTitle: 'Ajouter un type de finition',
    FinitionTypes_Title: 'Titre',
    FinitionTypes_Dialog_Title: 'Type de finition',
    FinitionTypes_Dialog_TitleField: 'Titre du type de finition*',
    FinitionTypes_Dialog_LabelField: 'Libellé du type de finition*',
    FinitionTypes_Dialog_DescriptionField: 'Descriptif',
    FinitionTypes_Dialog_HelpField: 'Aide',
    FinitionTypes_Dialog_RefProduitField: 'QCT du produit de référence (pour le prix)*',
    FinitionTypes_Dialog_VisbilityCondition: "Condition d'affichage",
    FinitionTypes_Dialog_AttributeField: 'Attribut :',
    FinitionTypes_Dialog_ValueField: 'Valeur :',
    FinitionTypes_Dialog_FormeChoice: 'Formes possibles*',
    FinitionTypes_Dialog_Rectangular: 'Rectangulaire',
    FinitionTypes_Dialog_Round: 'Rond',
    FinitionTypes_Dialog_IsRembordage: 'Rembordage*',
    FinitionTypes_Dialog_PerimeterChoice: 'Périmètres possibles*',
    FinitionTypes_Dialog_WholePerimeter: 'Tout le périmètre',
    FinitionTypes_Dialog_Lengths: '2 longueurs',
    FinitionTypes_Dialog_Widths: '2 largeurs',
    FinitionTypes_Dialog_Delete: 'Supprimer le type de finition',
    FinitionTypes_FormeProperties_Error: 'Vous devez selectionner au moins une forme possible',
    FinitionTypes_PerimetreProperties_Error:
        'Vous devez selectionner au moins un périmètre compatible',
    FinitionTypes_DeleteTitle: 'Supprimer le type de finition',
    FinitionTypes_DeleteTitleContent: 'Êtes-vous sûr de vouloir supprimer ce type de finition ?',
    FinitionTypes_SuccessDelete: 'Le type de finition a été supprimé avec succès',
    FinitionTypes_SuccessCreate: 'Le type de finition a été créé avec succès',
    FinitionTypes_SuccessUpdate: 'Le type de finition a été mis à jour avec succès',
    FinitionTypes_Error_InvalidRefProduit: 'Ce QCT est invalide',

    //* MATIERES FINITION
    MatiereFinitions_PageTitle: 'Matières finition',
    MatiereFinitions_AddButtonTitle: 'Ajouter une matière de finition',
    MatiereFinitions_Title: 'Titre',
    MatiereFinitions_Dialog_Title: 'Matière de finition',
    MatiereFinitions_Dialog_TitleField: 'Titre de la matière de finition*',
    MatiereFinitions_Dialog_LabelField: 'Libellé de la matière de finition*',
    MatiereFinitions_Dialog_RefProduitField: 'QCT du produit de référence (pour le prix)*',
    MatiereFinitions_Dialog_Delete: 'Supprimer la matière de finition',
    MatiereFinitions_DeleteTitle: 'Supprimer la matière de finition',
    MatiereFinitions_DeleteTitleContent:
        'Êtes-vous sûr de vouloir supprimer cette matière de finition ?',
    MatiereFinitions_SuccessDelete: 'La matière de finition a été supprimée avec succès',
    MatiereFinitions_SuccessCreate: 'La matière de finition a été créée avec succès',
    MatiereFinitions_SuccessUpdate: 'La matière de finition a été mise à jour avec succès',
    MatiereFinitions_Error_InvalidRefProduit: 'Ce QCT est invalide',
    MatiereFinitions_FinitionType: 'Type de finition',
    MatiereFinitionDetails_ParentFinitionTypes: 'Types de finition parents*',
    MatiereFinitionDetails_ParentFinitionTypes_Error:
        'Vous devez selectionner au moins un type de finition parent',

    //* FINITIONS
    Finitions_Header_Title: 'FINITIONS',
    Finitions_RefProduit: 'Référence',
    Finitions_LabelProduit: 'Libellé produit',
    Finitions_Status: 'Statut',
    Finitions_Status_Actif: 'Actif',
    Finitions_Status_Inactif: 'Inactif',
    Finitions_Disponibility: 'Disponibilité',
    Finitions_Disponibility_Indisponible: 'Indisponible',
    Finitions_Disponibility_Vendeur: 'Vendeur',
    Finitions_Disponibility_VendeurClient: 'Vendeur et client',
    Finitions_Dialog_Title: 'Finition',
    Finitions_Dialog_Produit_Title: 'Information du flux produit',
    Finitions_Dialog_Produit_Ref: 'Réf',
    Finitions_Dialog_Produit_LabelServices: 'Libellé services',
    Finitions_Dialog_Produit_Price: 'Prix',
    Finitions_Dialog_ParentMatiereFinition: 'Matière finition parente',
    Finitions_Dialog_Image: 'Image',
    Finitions_SuccessUpdate: 'La finition a été mise à jour avec succès',
    Finitions_SearchPlaceHolder: 'Rechercher une finition',
    Finitions_Header_MatiereTitle: 'Matière :',
    Finitions_Header_MatiereSelect_EmptyTitle: 'Non définie',

    //* TAPIS DE BASE
    TapisDeBase_PageTitle: 'Tapis de Base',
    TapisDeBase_RefProduit: 'Référence',
    TapisDeBase_LabelProduit: 'Libellé produit',
    TapisDeBase_Status: 'Statut',
    TapisDeBase_Status_Actif: 'Actif',
    TapisDeBase_Status_Inactif: 'Inactif',
    TapisDeBase_Disponibilite: 'Eligibilité',
    TapisDeBase_Disponibilite_Indisponible: 'Indisponible',
    TapisDeBase_Disponibilite_Vendeur: 'Vendeur',
    TapisDeBase_Disponibilite_VendeurClient: 'Vendeur et Client',
    TapisDeBase_Dialog_Title: 'Tapis de base',
    TapisDeBase_Dialog_Produit_Title: 'Information du flux produit',
    TapisDeBase_Dialog_Produit_Ref: 'Réf',
    TapisDeBase_Dialog_Produit_LabelWeb: 'Libellé web',
    TapisDeBase_Dialog_Produit_Price: 'Prix',
    TapisDeBase_SuccessUpdate: 'La finition a été mise à jour avec succès',
    TapisDeBase_SearchPlaceHolder: 'Rechercher un tapis de base',

    //* PREVIEW ASSETS
    Preview_Assets_Title: 'Assets de prévisualisation',

    ImportFluxLastImportDateFormat: 'Dernier import le {date}',
    ImportFluxProduitFormat: 'Flux produits : Le {date} => s3://{file_path}',
    ImportFluxPrestaFormat: 'Flux presta : Le {date} => s3://{file_path}',
};

export type StringKey = keyof typeof fr;
