import './Web/Themes/app.scss';

import React from 'react';

import { browserHistory } from './Configs/browserHistory';
import { CSMRoutes } from './Configs/CSMRoutes';
import { Constants } from './Core/Constants';
import { setupIntl } from './Core/Services/Data/I18n/SetupIntl';
import { Setup, SetupInitialized } from './Setup';
import { AppRouter } from './Web/Components/Templates/AppRouter/AppRouter';
import { AppWrapper } from './Web/Components/Templates/Wrappers/AppWrapper/AppWrapper';
import { LastImportFluxFilesProvider } from './Web/Context/LastImportFluxFiles/LastImportFluxFilesView';
import { PlatformContextProvider } from './Web/Context/PlatformContext/PlatformContext';
import { ThemeMuiProvider } from './Web/Themes/ThemeMuiProvider';

const { storeConfig }: SetupInitialized = Setup.initialize();

export const Application: React.FunctionComponent = () => {
    return (
        <AppWrapper
            store={storeConfig.store}
            persistor={storeConfig.persistor}
            setupIntl={setupIntl}
            getMuiTheme={() => ThemeMuiProvider.provideTheme()}
            withEssentialReload={() => Constants.needRefreshToken(window.location.pathname)}
        >
            <PlatformContextProvider>
                <LastImportFluxFilesProvider>
                    <AppRouter
                        routerHistory={browserHistory}
                        routes={CSMRoutes.getAppRoutes()}
                        defaultRoute={CSMRoutes.DEFAULT_ROUTE}
                    />
                </LastImportFluxFilesProvider>
            </PlatformContextProvider>
        </AppWrapper>
    );
};
