import * as React from 'react';
import Loadable from 'react-loadable';
import { Logger } from '../../../../../Core/Errors/Logger';

export interface LazyLoaderProps {
    readonly delay?: number;
    readonly children: React.ReactElement;
}

export const LazyLoader: React.FC<LazyLoaderProps> = ({ delay = 5 * 1000, children }) => {
    const timeoutRef = React.useRef<NodeJS.Timeout>();

    React.useEffect((): (() => void) => {
        timeoutRef.current = setTimeout((): void => {
            Loadable.preloadAll().catch((err: any): void => {
                Logger.debug('Error preloading all components ', err);
            });
        }, delay);
        return (): void => {};
    });

    return children;
};
