import { ApiRequest } from '../../Services/Api/ApiRequest';
import { Constants } from '../Constants';
import { ApiResponse } from '../Models/ApiData';
import { ApiUrls } from '../Models/ApiUrls';
import {
    AuthenticationResponse,
    ForgottenRequest,
    GrantType,
    LoginRequest,
    PostFrontTokenDto,
    RefreshRequest,
    ResetRequest,
} from '../Models/Authentication/AuthResponse';


export class AuthDataService {
    public static async login(
        email: string,
        password: string,
    ): Promise<AuthenticationResponse> {
        const body: LoginRequest = {
            email: email,
            password: password,
            grant_type: GrantType.Password,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.LOGIN, body, {
            noAuth: true,
            noCheckError: true
        });
    }
    public static async refresh(refreshToken: string): Promise<AuthenticationResponse> {
        const body: RefreshRequest = {
            refresh_token: refreshToken,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.REFRESH, body, {
            noCheckError: true,
            noAuth: true,
        });
    }


    public static async forgotten(email: string): Promise<ApiResponse> {
        const body: ForgottenRequest = { email };
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.FORGOTTEN, body, {
            noCheckError: true,
            noAuth: true,
        });
    }

    public static async reset(
        validation_code: string,
        email: string,
        new_password: string,
    ): Promise<AuthenticationResponse> {
        const body: ResetRequest = {
            email,
            new_password,
            validation_code,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.RESET, body, {
            noCheckError: true,
            noAuth: true,
        });
    }

    public static async getFrontToken(bodyRequest: PostFrontTokenDto): Promise<AuthenticationResponse> {
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.FRONT_TOKEN, bodyRequest);
    }
}

