import { AuthenticationState } from './typings/AuthenticationState';
import * as EmailActionTypes from './typings/EmailActionTypes';
import * as AuthenticationTypes from './typings/AuthenticationActionTypes';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    initialized: false,
    error: null,

    // EMAIL
    access_token: '',
    refresh_token: '',
    token_type: '',
};

const AuthenticationReducer = (
    state = initialState,
    action: AuthenticationTypes.AuthenticationAction | EmailActionTypes.EmailAction
): AuthenticationState => {
    switch (action.type) {
        default:
            return state;
        case AuthenticationTypes.AuthActionType.LOG_OUT:
            return { ...initialState, initialized: true };
        case AuthenticationTypes.AuthActionType.SET_AUTHENTICATED:
            return {
                ...state,
                access_token: action.payload.authorization.access_token,
                refresh_token: action.payload.authorization.refresh_token,
                token_type: action.payload.authorization.token_type,
                isAuthenticated: action.payload.isAuthenticated,
                initialized: action.payload.initialized,
            };

        //* EMAIL
        case EmailActionTypes.EmailActionType.LOGIN_EMAIL_SUCCESS:
            // action.payload : access_token, refresh_token, expires_in, token_type, expiration_date
            return { ...state, ...action.payload, error: false };
        case EmailActionTypes.EmailActionType.LOGIN_EMAIL_FAIL:
            // return initial state but preserve login method
            return { ...initialState, initialized: true, error: action.payload.error };
        case EmailActionTypes.EmailActionType.REFRESH_EMAIL_SUCCESS:
            // action.payload : access_token, refresh_token, expires_in, token_type, expiration_date
            return { ...state, ...action.payload, error: false };
        case EmailActionTypes.EmailActionType.REFRESH_EMAIL_FAIL:
            // return initial state (logged out) but preserve login method & set initialized
            return { ...initialState, initialized: true, error: action.payload.error };
    }
};

export { AuthenticationReducer };
