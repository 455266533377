import * as Redux from 'redux';
import { AuthService } from '../Core/Services/Data/Authentication/AuthenticationService';
import { AppState, store } from './CsmStore';


export class StoreProvider {

    public static getStore = (): Redux.Store => store;

    public static getState = (): AppState => StoreProvider.getStore().getState();
    public static dispatch = (action: any) => StoreProvider.getStore().dispatch(action);

    public static getToken = async (requestBy?: string): Promise<string> => {
        //const authToken: string = await this.getAuthorization(`[ REQUESTED BY ] withAuth for ${url}`);
        return AuthService.getAuthorization();
    }

    public static getMessages = (): Record<string, string> => StoreProvider.getState().I18n.messages;
}