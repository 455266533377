import { AppRoutes } from '../../Core/App/AppRoutes';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { CarpetPageLoading } from '../Pages/Carpets/CarpetPage/CarpetPageLoading';

export class CarpetRoutes {
    private static getCarpetRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.CARPET_DETAILS,
            component: CarpetPageLoading,
        },
    });

    public static getCarpetRoutes = () => [CarpetRoutes.getCarpetRoute()];
}
