import { CsmStore, CsmStoreConfig } from "./Configs/CsmStore";
import { Constants } from "./Core/Constants";
import { LogsSender } from "./Core/LogsSender/LogsSender";
import { ApiUrls } from "./Core/Models/ApiUrls";
import { AuthService } from "./Core/Services/Data/Authentication/AuthenticationService";
import { ApiRequest } from "./Services/Api/ApiRequest";
import { NavigationService } from "./Web/Services/NavigationService";

export type SetupInitialized = { storeConfig: CsmStoreConfig };

export class Setup {
    public static initialize = (): SetupInitialized => {

        console.log('🚀🚀🚀🚀🚀 | Setup START INITIALIZATION');
        const storeConfig = CsmStore.getStore();

        LogsSender.setupLogsSender();

        //* Initialize Services
        NavigationService.initialize();

        //* Initialize api client
        ApiRequest.initializeApiClient({
            baseURL: Constants.getRoot(),
            refreshUrl: ApiUrls.REFRESH,
            timeout: 1000 * 60, //* 🕐 1 minute;
            refreshAuthorization: (): Promise<string> => AuthService.refreshAuthentication(),
            getAuthorization: (): Promise<string> => AuthService.getAuthorization()
        })

        console.log('🚀🚀🚀🚀🚀 | INIT API CLIENT : OK');

        console.log('🚀🚀🚀🚀🚀 | INIT : DONE');

        return { storeConfig };
    };
}
