import { AppRoutes } from '../../Core/App/AppRoutes';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { EscalierPageLoading } from '../Pages/Escaliers/EscalierPage/EscalierPageLoading';

export class EscalierRoutes {
    private static getEscalierRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.ESCALIERS_DETAILS,
            component: EscalierPageLoading,
        },
    });

    public static getEscalierRoutes = () => [EscalierRoutes.getEscalierRoute()];
}
