import { ApiRequest } from "../../Services/Api/ApiRequest"
import { ApiUrls } from "../Models/ApiUrls";
import { LastImportFluxFilesRepose } from "../Models/ImportFluxFiles/ImportFluxFiles"



export class ImportFluxFilesService {

    public static getLastImportFluxFiles = (): Promise<LastImportFluxFilesRepose> => {
        return ApiRequest.get<LastImportFluxFilesRepose>(ApiUrls.LAST_IMPORT_FLUX_FILES);
    }
}