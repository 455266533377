/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, formatDistance, isBefore, isValid, parseISO } from 'date-fns/esm';
import { fr } from 'date-fns/esm/locale';

export class DateUtils {
    /**
     * @param {String|Date} date - ISO format
     * @param {*} defaultValue
     * @param {Object|String} options
     * @param {Boolean} options.acceptFalsyDefault - return the 'defaultDate' even if it's falsy
     * @returns {Date} - the parsed Date, or the default date, or the current date in that order
     */
    static customParseDate = <DefaultValue = any>(
        date: string | Date,
        defaultDate: DefaultValue,
        { acceptFalsyDefault }: { acceptFalsyDefault?: boolean } = {}
    ): Date | DefaultValue => {
        if (typeof date === 'string' && isValid(parseISO(date))) return parseISO(date);

        if (date instanceof Date && isValid(date)) return date;

        if (acceptFalsyDefault) return defaultDate;

        return defaultDate || new Date();
    };

    static formatDateCustom = <DefaultValue extends any>(
        rawDate: string | Date,
        customFormat: string,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue => {
        const parsedDate = DateUtils.customParseDate<DefaultValue | string>(rawDate, defaultValue, {
            acceptFalsyDefault: true,
        });

        if (parsedDate === defaultValue) return defaultValue;

        return parsedDate
            ? format(parsedDate as Date, customFormat, {
                useAdditionalWeekYearTokens: true,
                locale: fr,
            })
            : defaultValue;
    };

    /**
     * @param {String|Date} rawDate
     * @param {Date|Number} baseDate
     * @param {*} defaultValue
     * @param {Object|String} options
     * @param {Boolean} options.includeSeconds - distances less than a minute are more detailed
     * @param {Boolean} options.addSuffix - result indicates if the second date is earlier or later than the first
     */
    static formatDistanceCustom = <DefaultValue extends any>(
        rawDate: string | Date,
        baseDate: Date,
        defaultValue: DefaultValue | string | null = '-',
        { includeSeconds, addSuffix }: { includeSeconds?: boolean; addSuffix?: boolean } = {}
    ): null | string | DefaultValue => {
        const parsedDate = DateUtils.customParseDate<DefaultValue | string | null>(
            rawDate,
            defaultValue,
            {
                acceptFalsyDefault: true,
            }
        );

        if (parsedDate === defaultValue) return defaultValue;

        return parsedDate
            ? formatDistance(parsedDate as Date, baseDate, {
                includeSeconds,
                addSuffix,
                locale: fr,
            })
            : defaultValue;
    };

    /**
     * @param {String|Date} rawDate
     * @param {*} defaultValue
     * @returns {String} the date formatted as 'dd/MM/yy' or the default value
     */
    static formatDateShort = <DefaultValue extends any>(
        rawDate: string | Date,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue =>
        DateUtils.formatDateCustom<DefaultValue>(rawDate, 'dd/MM/yy', defaultValue);

    /**
     * @param {String|Date} rawDate
     * @param {*} defaultValue
     * @returns {String} the date formatted as 'd MMMM Y' or the default value
     */
    static formatDateLong = <DefaultValue extends any>(
        rawDate: string | Date,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue =>
        DateUtils.formatDateCustom<DefaultValue>(rawDate, 'd MMMM Y', defaultValue);

    /**
     * @param {String|Date} rawDate
     * @param {Object|String} i18n - the i18n state or the 'at' label
     * @param {*} defaultValue
     * @returns {String}
     */
    static formatAtDateTime = <DefaultValue extends any>(
        rawDate: string | Date,
        i18n: { messages: { [x: string]: string } } | string,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue => {
        const label = typeof i18n === 'string' ? i18n : i18n.messages['label.at'];

        return DateUtils.formatDateCustom<DefaultValue>(
            rawDate,
            `dd/MM/yyyy '${label}' HH:mm`,
            defaultValue
        );
    };

    /**
     * @param {String|Date} rawDate
     * @param {*} defaultValue
     * @returns {String}
     */
    static formatDateTime = <DefaultValue extends any>(
        rawDate: string | Date,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue =>
        DateUtils.formatDateCustom<DefaultValue>(rawDate, `dd/MM/yyyy - HH'h'mm`, defaultValue);

    /**
     * @param {String|Date} rawFirst
     * @param {String|Date} rawSecond
     * @returns {Boolean} if the first date is before the second. Will return false if any
     * of the two dates are not valid
     */

    static isDateBeforeWithParse = (rawFirst: string | Date, rawSecond: string | Date): boolean => {
        const first = DateUtils.customParseDate<null>(rawFirst, null, { acceptFalsyDefault: true });
        const second = DateUtils.customParseDate<null>(rawSecond, null, {
            acceptFalsyDefault: true,
        });

        if (!first || !second) return false;

        return isBefore(first, second);
    };

    static getDateFromMilliseconds = (milliseconds: number) => {
        var hour, minute, seconds;
        seconds = Math.floor(milliseconds / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        return {
            hour: hour,
            minute: minute,
            seconds: seconds,
        };
    };

    static formatTwoDigitsNumber = (number: number) => {
        return ('0' + number).slice(-2);
    };

    static getNumberDaysBetweenTwoDates = (startDate: Date, endDate: Date): number => {
        const numberOfMillisecondsInADay = 86400000;

        const startTime = startDate.getTime() / numberOfMillisecondsInADay;
        const endTime = endDate.getTime() / numberOfMillisecondsInADay;
        return Math.trunc(endTime - startTime);
    };

    static setDatePrecisionToDay = (date: Date) => {
        const cpyDate = new Date(date);

        cpyDate.setHours(0);
        cpyDate.setMinutes(0);
        cpyDate.setSeconds(0);
        cpyDate.setMilliseconds(0);

        return cpyDate;
    };
}
