import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

//* Can be usefull for platform context like #ANDROID #IPHONE #IPAD

// Breakpoints
// xs, extra-small: 0px.
// sm, small: 600px.
// md, medium: 960px.
// lg, large: 1280px.
// xl, extra-large: 1920px.

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

export const BreakpointSize = {
    XS: 0, //* mobile
    SM: 600, //* tablete like nexus 7
    MD: 960, //* ipad
    LG: 1280,
    XL: 1920,
} as const;

export interface IPlatformContextValue {
    isMobile: boolean;
}

const DefaultPlatformContextValue: IPlatformContextValue = {
    isMobile: false,
};

const PlatformContext = React.createContext<IPlatformContextValue>(DefaultPlatformContextValue);
PlatformContext.displayName = 'PlatformContext';
export const usePlatformContext = (): IPlatformContextValue => React.useContext(PlatformContext);

export const PlatformContextProvider: React.FunctionComponent = ({ children }) => {
    //! breakpoints below XS === 600px
    //const isMobile = useMediaQuery(useTheme().breakpoints.down(744));
    const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));

    const platformContextValue: IPlatformContextValue = {
        isMobile,
    };

    return (
        <PlatformContext.Provider value={platformContextValue}>{children}</PlatformContext.Provider>
    );
};
