import { CustomHttpErrors, ErrorHandler } from '../../Libs/xhr/ErrorHandler';
import { HttpStatusCode } from '../../Libs/xhr/HttpStatusCode';
import { NavigationService } from '../../Web/Services/NavigationService';
import { ToastService } from '../../Web/Services/ToastService';
import { AppRoutes } from '../App/AppRoutes';
import { LogLevel } from '../LogsSender/LogLevel';
import { AuthService } from '../Services/Data/Authentication/AuthenticationService';
import { I18n } from '../Services/Data/I18n/I18nService';
import { Messages } from '../Services/Data/I18n/typings/LocalMessageActionTypes';
import { Logger } from './Logger';

const logError = (logMessage: string, customHttpErrors: CustomHttpErrors, dataError: any) => {
    Logger.log(`${logMessage}`, LogLevel.Error, { ...customHttpErrors, dataError });
};

export const handleError = async (error: any): Promise<boolean> => {

    const customHttpErrors = ErrorHandler.getCustomHttpErrors(error);

    const {
        isApiError,
        httpStatusCode,
        errorCode,
        //errorMessage,
        checkError,
    }: CustomHttpErrors = customHttpErrors;

    const dataError = {
        log_level: 'error',
        timestamp: new Date(),
        context: { userAgent: navigator.userAgent, location: window.location },
        error,
    };

    if (httpStatusCode === HttpStatusCode.Unauthorized) {
        ToastService.showError({ content: I18n.get('PermissionDialog_NotAuthenticated') });
        await AuthService.removeAuthorizationToken();
        //* il n'y pas d'accueil ou d'écran de connection
        //* pour se logguer si le token est expiré
        //this.navigationService.value().goTo({ route: AppRoutes.HOME, replace: true });
        return Promise.resolve(true);
    }

    if (httpStatusCode === HttpStatusCode.BadRequest) {
        logError('🔥🔥🔥🔥🔥 | CSMErrorHandler BadRequest', customHttpErrors, dataError);
    }

    if (httpStatusCode === HttpStatusCode.Forbidden) {
        logError('🔥🔥🔥🔥🔥 | CSMErrorHandler Forbidden', customHttpErrors, dataError);

        //await this.authService.value().logout('from Forbidden request');
        ToastService.showError({ content: I18n.get('Error_FORBIDDEN') });
        NavigationService.goTo({ route: AppRoutes.LOGIN, replace: true });
        return Promise.resolve(true);
    }

    if (isApiError && checkError) {
        logError('🔥🔥🔥🔥🔥 | CSMErrorHandler Api', customHttpErrors, dataError);

        const messages: Messages = I18n.getMessages();

        //* let message = 'An error occurred with the server';
        const message =
            messages[`Error_${errorCode}`] ||
            errorCode ||
            //errorMessage || //* can be generic error so not usable
            messages[`Error_GenericError`] ||
            //* default message in FR 🇫🇷
            "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.";

        ToastService.showError({ content: message });
    }

    return Promise.resolve(true);
};