import './CustomLoader.scss';
import 'loaders.css/loaders.min.css';

import classNames from 'classnames';
import React from 'react';
import Loader, { LoaderType } from 'react-loaders';

export interface CustomLoaderProps {
    position?: 'left' | 'center' | 'right';
    type?: LoaderType;
    classes?: {
        root?: string;
        loader?: string;
    };
    visible?: boolean;
    darkColor?: boolean;
}

export const CustomLoader = ({
    position = 'center',
    type = 'ball-pulse',
    visible = true,
    classes,
    darkColor,
}: CustomLoaderProps) => {
    if (!visible) {
        return null;
    }

    const enhancedRootClasses = classNames('CustomLoader-root', position, classes?.root);
    const enhancedLoaderClasses = classNames('CustomLoader-loader', classes?.loader, {
        dark: darkColor,
    });

    return (
        <div className={enhancedRootClasses}>
            <Loader active innerClassName={enhancedLoaderClasses} type={type} />
        </div>
    );
};
