import React from 'react';

import { ButtonClassType, CustomButton } from '../../CustomButton/CustomButton';

export interface DialogButtonType {
    title?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    variant: ButtonClassType;
    disabled?: boolean;
}

export const getDialogButton = ({
    title,
    onClick,
    variant,
    disabled = false,
}: DialogButtonType) => {
    return (
        title && (
            <CustomButton
                type="button"
                variant={variant}
                title={title}
                onClick={onClick}
                disabled={disabled}
                fullWidth
                classes={{ root: 'dialog-button' }}
            />
        )
    );
};
