import React from 'react';
import { Constants } from '../Core/Constants';

export class CacheService {
    private static formatKey = (key: string) => `csm-location-reloaded:${key}`;

    public static reloadPageOnBuildIdDiff = async (location: string) => {
        const key = CacheService.formatKey(location);

        if (CacheService.hasPageBeenReloaded(key) === 'true') {
            return;
        }

        try {
            const currentBuildId = Constants.VERSION_NUMBER;
            const localConfigResponse = await fetch(`/build_config.txt`);
            const localConfig = await localConfigResponse.json();

            sessionStorage.setItem(
                'csm-CacheService.reloadPageOnBuildIdDiff',
                JSON.stringify({ localConfig, currentBuildId })
            );

            if (localConfig['build_id'] !== Constants.VERSION_NUMBER) {
                CacheService.setHasPageBeenReloaded(key);
                sessionStorage.setItem('csm-last-location-reloaded', location);
                window.location.reload();
            }
        } catch (error) {
            console.error('csm-CacheService.reloadPageOnBuildIdDiff', { error });
        }
    };

    private static hasPageBeenReloaded = (key: string) => sessionStorage.getItem(key);
    private static setHasPageBeenReloaded = (key: string, value: boolean = true) =>
        sessionStorage.setItem(key, `${value}`);
}

export const useCacheForceReloadIfNeeded = (location: string) => {
    React.useEffect(() => {
        CacheService.reloadPageOnBuildIdDiff(location);
    }, [location]);
};
