import { StoreProvider } from '../../Configs/StoreProvider';
import * as AuthenticationTypes from '../../Core/Services/Data/Authentication/typings/AuthenticationActionTypes';


export enum AppActionType {
    APP_ESSENTIAL_RELOAD = 'app/essential/reload',
}

export class AppActions {
    static triggerEssentialReload = () => ({ type: AppActionType.APP_ESSENTIAL_RELOAD });
    static getMeInfo = () => {
        const authState = StoreProvider.getState().Authentication;
        return {
            type: AuthenticationTypes.AuthActionType.SET_AUTHENTICATED,
            payload: {
                isAuthenticated: true,
                authorization: authState,
                initialized: true,
                initializedReason: AuthenticationTypes.InitializedReason.REFRESH_SUCCESS,
            },
        };
    };
}
