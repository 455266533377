import { Theme } from '@material-ui/core/styles';
import React from 'react';

import { CustomDialogContainer } from '../../CustomDialogContainer/CustomDialogContainer';
import { CustomToastContainer } from '../../CustomToastContainer/CustomToastContainer';
import { MuiApp } from '../../MuiApp/MuiApp';
import { ReduxApp } from '../../ReduxApp/ReduxApp';
import { IntlWrapper } from '../IntlWrapper/IntlWrapper';
import { LazyLoader } from '../LazyLoader/LazyLoader';
import { EssentialsActionsGate } from '../MountActionsGate/EssentialsActionsGate';

export interface AppWrapperProps {
    readonly store: any;
    readonly persistor: any;
    readonly content?: React.ReactNode;
    readonly children?: React.ReactNode;

    //* Array of Promises to execute on mount
    readonly mountActions?: Array<() => Promise<boolean>>;
    //* Automatically setup react-intl & intl
    //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
    readonly setupIntl?: () => Promise<boolean>;
    //* If should trigger 'BaseActions.triggerEssentialReload'
    readonly withEssentialReload: () => boolean;

    //* Mui Theme
    readonly getMuiTheme: () => Theme;
}

export const AppWrapper = ({
    store,
    persistor,
    content,
    children,
    mountActions = [],
    setupIntl,
    withEssentialReload = () => true,

    getMuiTheme,
}: AppWrapperProps) => (
    <MuiApp getMuiTheme={getMuiTheme}>
        <ReduxApp store={store} persistor={persistor}>
            <EssentialsActionsGate
                actions={mountActions}
                setupIntl={setupIntl}
                withEssentialReload={withEssentialReload()}
            >
                <IntlWrapper>
                    <LazyLoader>
                        <div style={{ height: '100%' }}>
                            {children ? children : content}
                            <CustomToastContainer />
                            <CustomDialogContainer />
                        </div>
                    </LazyLoader>
                </IntlWrapper>
            </EssentialsActionsGate>
        </ReduxApp>
    </MuiApp>
);
