import { AuthActionType, SetAuthenticatedAction } from '../Services/Data/Authentication/typings/AuthenticationActionTypes';
import { MeActions } from '../Services/Data/Me/MeActions';
import { MeService } from '../Services/Data/Me/MeService';

export const MeMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
    if (action) {
        switch (action.type) {
            case AuthActionType.SET_AUTHENTICATED:
                const setAuthAction = action as SetAuthenticatedAction;
                if (setAuthAction.payload.isAuthenticated) {
                    MeService.getMyInfo().catch((err: any) => err);
                } else {
                    dispatch(MeActions.setInfo(false));
                }
                break;
            default:
                break;
        }
    }
    return next(action);
};
