import { subHours } from 'date-fns';
import { addSeconds, parseISO } from 'date-fns/esm';

import { AuthenticationResult } from '../../../../Models/Authentication/AuthResponse';

export class AuthUtils {
    // e.g. "Bearer abfaf83eaeda473fa64c45f42ca11152"
    public static getAuthorizationFromToken = (token_type: string, token: string) => `${token_type} ${token}`;

    public static toAuthWithExpirationDate = (auth: AuthenticationResult) => ({
        ...auth,
        expiration_date: addSeconds(new Date(), auth.expires_in || 0).toISOString(),
    });


    /** @param {Number} expirationDate ISO string**/
    public static authenticationIsExpired = (expirationDate: any) => {
        if (!expirationDate) {
            return true;
        }
        return new Date() > subHours(parseISO(expirationDate), 2);
    };
}
