import { AppRoutes } from '../../Core/App/AppRoutes';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { RouteItem } from '../Components/Templates/AppRouter/RouteItem';
import { VersionEscalierListPageLoading } from '../Pages/Versions/VersionEscalierListPage/VersionEscalierListPageLoading';
import { VersionTapisListPageLoading } from '../Pages/Versions/VersionTapisListPage/VersionTapisListPageLoading';
import { VersionDoormatListPageLoading } from '../Pages/Versions/VersionDoormatListPage/VersionDoormatListPageLoading';

export class VersionParametrageRoutes {
    private static getCarpetVersionParametrageListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.VERSION_CARPET_LIST,
            component: VersionTapisListPageLoading,
        },
    });
    private static getEscalierVersionParametrageListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.VERSION_ESCALIERS_LIST,
            component: VersionEscalierListPageLoading,
        },
    });
    private static getDoormatVersionParametrageListRoute = (): RouteItem => ({
        render: PrivateRoute,
        props: {
            path: AppRoutes.VERSION_DOORMATS_LIST,
            component: VersionDoormatListPageLoading,
        },
    });


    public static getVersionParametrageRoutes = () => [
        VersionParametrageRoutes.getCarpetVersionParametrageListRoute(),
        VersionParametrageRoutes.getEscalierVersionParametrageListRoute(),
        VersionParametrageRoutes.getDoormatVersionParametrageListRoute(),
    ];
}
