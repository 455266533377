import { ApiDataResponse } from '../ApiData';

export enum TokenType {
    Bearer = 'Bearer',
}

export enum GrantType {
    Password = 'Password',
}

export interface LoginRequest {
    email: string;
    password: string;
    grant_type: GrantType;
    client_id: string;
    client_secret: string;
}

export interface RefreshRequest {
    refresh_token: string;
    client_id: string;
    client_secret: string;
}

export interface ForgottenRequest {
    email: string;
}

export interface ResetPasswordParams {
    email: string;
    token: string;
}
export interface ResetRequest {
    email: string;
    validation_code: string;
    new_password: string;
    client_id: string;
    client_secret: string;
}

export interface AuthenticationResult {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in?: number; //* seconds, from when the token was acquired
    expiration_date?: string; //* ISO string
}

export enum AuthenticateAsUserTypeDto {
    Store = 'Store',
    Customer = 'Customer'
}
export interface PostFrontTokenDto {
    readonly authenticated_as_user_type: AuthenticateAsUserTypeDto;
}

export type AuthenticationResponse = ApiDataResponse<AuthenticationResult>;
