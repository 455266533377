import './LastImportFluxFilesView.scss';

import { Paper, styled, Tooltip } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ImportFluxFilesService } from '../../../Core/DataServices/ImportFluxFilesService';
import { wrapError } from '../../../Core/Errors/Errors';
import { StringKey } from '../../../Core/Locales/fr';
import {
    ImportFluxFileDto,
    LastImportFluxFilesDto,
} from '../../../Core/Models/ImportFluxFiles/ImportFluxFiles';
import { DateUtils } from '../../../Core/Utils/DateUtils';
import { CustomLoader } from '../../Components/Templates/CustomLoader/CustomLoader';
import { useViewState } from '../../Hooks/ViewState';

type LastImportFluxFilesContextValue = { loading?: boolean; details?: LastImportFluxFilesDto };
const LastImportFluxFilesContext = React.createContext<LastImportFluxFilesContextValue>({});
const useLastImportFluxFilesContext = () => React.useContext(LastImportFluxFilesContext);

export const LastImportFluxFilesProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { getState, update } = useViewState<LastImportFluxFilesContextValue>();

    React.useEffect(() => {
        wrapError(
            async () => {
                update({ loading: true });
                const response = await ImportFluxFilesService.getLastImportFluxFiles();
                update({ loading: false, details: response.data });
            },
            {
                errorHandler: (error) => {
                    update({ loading: false });
                    return Promise.resolve(true);
                },
            }
        );
    }, []);

    const { loading, details } = getState();
    return (
        <LastImportFluxFilesContext.Provider value={{ loading, details }}>
            {children}
        </LastImportFluxFilesContext.Provider>
    );
};

type ImportFileLineProps = { id: StringKey; file?: ImportFluxFileDto };

export const LastImportFluxFilesView = () => {
    const { loading, details } = useLastImportFluxFilesContext();

    const DATE_FORMAT = "dd/MM/yy 'à' HH'h'mm";
    const formatDate = (date: Date): string => DateUtils.formatDateCustom(date, DATE_FORMAT);

    const ToolTipLastImportFluxFiles = () => {
        const ImportFileLine = ({ id, file }: ImportFileLineProps) => {
            if (!file) {
                return null;
            }
            return (
                <div className="ImportFileLine-root">
                    <FormattedMessage
                        id={id}
                        values={{ file_path: file.file_path, date: formatDate(file.import_date) }}
                    />
                </div>
            );
        };

        return (
            <Paper classes={{ root: 'ToolTipLastImportFluxFiles-content' }}>
                <ImportFileLine id="ImportFluxProduitFormat" file={details?.flux_produit_file} />
                <ImportFileLine id="ImportFluxPrestaFormat" file={details?.flux_prestation_file} />
            </Paper>
        );
    };

    return (
        <Tooltip
            title={<ToolTipLastImportFluxFiles />}
            classes={{ tooltip: 'ToolTipLastImportFluxFiles-root' }}
        >
            <TooltipContent>
                {loading && <CustomLoader darkColor />}
                {details?.import_date && (
                    <FormattedMessage
                        id="ImportFluxLastImportDateFormat"
                        values={{ date: formatDate(details.import_date) }}
                    />
                )}
            </TooltipContent>
        </Tooltip>
    );
};

const TooltipContent = styled('div')({
    marginRight: '54px',
    fontSize: '14px',
});
