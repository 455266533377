import './CustomToast.scss';

import className from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import iconError from '../../../assets/icons/toast/error.svg';
import iconInfo from '../../../assets/icons/toast/info.svg';
import iconSuccess from '../../../assets/icons/toast/success.svg';
import { ToastActionOptions } from '../../../Services/ToastService';

export enum CustomToastType {
    ERROR = 'Error',
    SUCCESS = 'Success',
    INFO = 'Info',
}

export interface CustomToastProps {
    type: CustomToastType;
    title?: React.ReactNode;
    content: React.ReactNode;
    actionOptions?: ToastActionOptions;
}

export const CustomToast = ({
    type,
    title,
    content,
    actionOptions,
}: CustomToastProps): React.FunctionComponent => {
    const toastClass = className('custom-toast-container', { [type]: type });

    let icon: string;

    switch (type) {
        case CustomToastType.ERROR:
            icon = iconError;
            break;
        case CustomToastType.SUCCESS:
            icon = iconSuccess;
            break;
        default:
            icon = iconInfo;
    }

    const ToastContainer: React.FunctionComponent = ({ closeToast }: any) => {
        return (
            <div className={toastClass}>
                <img src={icon} alt="delete" className="toast-icon" />
                <div className="body">
                    <div className="title">
                        {title ?? <FormattedMessage id={`Toast_Title${type}`} />}
                    </div>
                    <div className="content">{content}</div>
                </div>
            </div>
        );
    };

    return ToastContainer;
};
