import { AppRoutes } from './App/AppRoutes';

export class Constants {
    public static AUTH_STORAGE_KEY = 'csm-back-office-root';
    //public static AUTH_STORAGE_KEY = 'csm-back-auth' replace csm-back-office-root by csm-back-auth

    public static CLIENT_ID = 'CSM_BACK';
    public static CLIENT_SECRET = 'Ideine';
    private static API_BASE_URL = '__API_HOST__';
    private static API_BASE_URL_DEV = 'https://csm-api-dev.azurewebsites.net';
    //private static API_BASE_URL_DEV = 'http://localhost:5001';

    public static ENVIRONMENT = '__ENVIRONMENT__';

    public static isLocalDev = process.env.NODE_ENV === 'development';

    public static VERSION_NUMBER = Constants.isLocalDev ? '0.1.0' : '__BUILD_ID__';

    public static TAPIS_DE_BASE_COUNT: number = 50;

    public static LOGIN_ERROR: string = 'LOGIN_ERROR';
    public static WEAK_PASSWORD: string = 'WEAK_PASSWORD';
    public static REFERENCING_MATIERE_FINITION_FORBIDS_DELETION: string =
        'REFERENCING_MATIERE_FINITION_FORBIDS_DELETION';
    public static REFERENCING_FINITION_FORBIDS_DELETION: string =
        'REFERENCING_FINITION_FORBIDS_DELETION';

    private static IMAGE_URL_ROOT: string = '__IMAGE_CDN__';
    private static IMAGE_URL_ROOT_DEV: string = 'https://media-uat.cdn.saint-maclou.com';
    public static toImageUrl = (
        imageKey?: string,
        width?: number,
        height?: number
    ): string | undefined => {
        if (!imageKey) {
            return undefined;
        }
        return Constants.toImageCdnUrl(
            Constants.IMAGE_URL_ROOT,
            Constants.IMAGE_URL_ROOT_DEV,
            imageKey,
            width,
            height
        );
    };
    private static ASSET_IMAGE_URL_ROOT: string = '__ASSET_IMAGE_CDN__';
    private static ASSET_IMAGE_URL_ROOT_DEV: string = 'https://media-csm-pp.cdn.saint-maclou.com';
    public static toAssetImageCdnUrl = (
        imageKey: string,
        width?: number,
        height?: number
    ): string =>
        Constants.toImageCdnUrl(
            Constants.ASSET_IMAGE_URL_ROOT,
            Constants.ASSET_IMAGE_URL_ROOT_DEV,
            imageKey,
            width,
            height
        );

    public static toImageCdnUrl = (
        urlRoot: string,
        urlRootDev: string,
        imageKey: string,
        width?: number,
        height?: number
    ): string => {
        if (width && height) {
            return `${Constants.isLocalDev ? urlRootDev : urlRoot}/${width}x${height}/${imageKey}`;
        }
        return `${Constants.isLocalDev ? urlRootDev : urlRoot}/${imageKey}`;
    };

    public static getRoot = (): string =>
        Constants.isLocalDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL;

    public static LOGS = {
        elasticSearchUrl: Constants.isLocalDev
            ? 'https://clicandpose-functions.azurewebsites.net/api/ClicAndPoseMobileLogs?code=U1UZ3EiC5vn6FmrnzR7QGTEz/C1qQ1lMYVFCJQGg1CwWiO7IrItN0A==&clientId=default'
            : '__ELASTIC_SEARCH_LOG_URL__',
        elasticSearchIndex: Constants.isLocalDev
            ? 'csm-back-web-local'
            : '__ELASTIC_SEARCH_INDEX__',
    };

    public static needRefreshToken = (locationPathname: string): boolean => {
        return !locationPathname.startsWith(AppRoutes.LOGIN);
    };
}
