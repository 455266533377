import { VersionType } from './Versions/VersionDto';

export const ApiUrls = {
    LOGIN: '/api/v1/back/login',
    REFRESH: '/api/v1/back/refresh',
    FORGOTTEN: '/api/v1/back/password/forgotten',
    RESET: '/api/v1/back/password/reset',
    FRONT_TOKEN: '/api/v1/back/front_token',

    ME: '/api/v1/back/me',

    //* CONFIGURATIONS
    CONFIGURATIONS: '/api/v1/back/settings',

    //* USERS
    USER_LIST: '/api/v1/back/admins',
    CREATE_USER: '/api/v1/back/admins',
    DELETE_USER: (userId: string) => `/api/v1/back/admins/${userId}`,
    UPDATE_USER: (userId: string) => `/api/v1/back/admins/${userId}`,
    SEND_MAIL_TO_USER: (userId: string) => `/api/v1/back/admins/${userId}/invite`,

    GET_IMAGE: (imageId: string) => '',
    UPLOAD_IMAGE: (imageId: string) => `/api/v1/back/images/${imageId}`,

    //* VERSIONS
    VERSION_LIST: (versionType: VersionType) => `/api/v1/back/${versionType}/versions`,
    VERSION_CREATE: (versionType: VersionType) => `/api/v1/back/${versionType}/versions`,
    VERSION_DUPLICATE: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}/duplicate`,
    VERSION_DETAILS: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}`,
    VERSION_UPDATE: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}`,
    VERSION_DELETE: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}`,
    VERSION_EXPORT: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}/export`,
    VERSION_IMPORT: (versionType: VersionType) => `/api/v1/back/${versionType}/versions/import`,

    //* VALEURS
    VALEURS_LIST: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}/valeurs`,
    VALEURS_EXPORT: (versionType: VersionType, versionId: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}/valeurs/export`,
    VALEUR_UPDATE: (versionType: VersionType, versionId: string, key: string) =>
        `/api/v1/back/${versionType}/versions/${versionId}/valeurs/${key}`,

    //* GUIDS
    GET_GUID_LIST: '/api/v1/back/guids',

    //* CARPETS
    CARPET_FINITION_TYPES: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types`,
    CARPET_FINITION_TYPE: (versionId: string, finitionTypeId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types/${finitionTypeId}`,
    CARPET_FINITION_TYPE_CREATE: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types`,
    CARPET_FINITION_TYPE_UPDATE: (versionId: string, finitionTypeId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types/${finitionTypeId}`,
    CARPET_FINITION_TYPE_DELETE: (versionId: string, finitionTypeId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types/${finitionTypeId}`,
    CARPET_FINITION_TYPES_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finition_types`,
    CARPET_MATIERE_FINITIONS: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions`,
    CARPET_MATIERE_FINITION: (versionId: string, matiereFinitionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions/${matiereFinitionId}`,
    CARPET_MATIERE_FINITION_CREATE: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions`,
    CARPET_MATIERE_FINITION_UPDATE: (versionId: string, matiereFinitionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions/${matiereFinitionId}`,
    CARPET_MATIERE_FINITION_DELETE: (versionId: string, matiereFinitionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions/${matiereFinitionId}`,
    CARPET_MATIERE_FINITIONS_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/matiere_finitions`,
    CARPET_FINITIONS: (versionId: string) => `/api/v1/back/carpets/versions/${versionId}/finitions`,
    CARPET_FINITION: (versionId: string, finitionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finitions/${finitionId}`,
    CARPET_FINITION_UPDATE: (versionId: string, finitionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finitions/${finitionId}`,
    CARPET_FINITIONS_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/finitions`,
    CARPET_TAPIS_DE_BASE: (versionId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/tapis_de_base`,
    CARPET_TAPIS_DE_BASE_DETAILS: (versionId: string, tapisDeBaseId: string) =>
        `/api/v1/back/carpets/versions/${versionId}/tapis_de_base/${tapisDeBaseId}`,

    //* ESCALIERS
    ESCALIER_MARCHES_PRODUITS: (code: string) => `/api/v1/back/escaliers/marches/produits/${code}`,

    ESCALIER_MARCHES: (versionId: string) => `/api/v1/back/escaliers/versions/${versionId}/marches`,
    ESCALIER_MARCHE: (versionId: string, marcheId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/marches/${marcheId}`,
    ESCALIER_MARCHES_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/marches`,
    ESCALIER_MARCHES_POST: (versionId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/marches`,
    ESCALIER_MARCHES_UPDATE: (versionId: string, marcheId?: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/marches/${marcheId}`,
    ESCALIER_MARCHES_DELETE: (versionId: string, marcheId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/marches/${marcheId}`,

    ESCALIER_CONTREMARCHES: (versionId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches`,
    ESCALIER_CONTREMARCHE: (versionId: string, contremarcheId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches/${contremarcheId}`,
    ESCALIER_CONTREMARCHES_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches`,
    ESCALIER_CONTREMARCHES_POST: (versionId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches`,
    ESCALIER_CONTREMARCHES_UPDATE: (versionId: string, contremarcheId?: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches/${contremarcheId}`,
    ESCALIER_CONTREMARCHES_DELETE: (versionId: string, contremarcheId: string) =>
        `/api/v1/back/escaliers/versions/${versionId}/contremarches/${contremarcheId}`,

    //* DOORMATS
    DOORMAT_GAMMES: (versionId: string) => `/api/v1/back/doormats/versions/${versionId}/gammes`,
    DOORMAT_GAMME: (versionId: string, gammeId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/gammes/${gammeId}`,
    DOORMAT_GAMMES_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/gammes`,
    DOORMAT_GAMMES_POST: (versionId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/gammes`,
    DOORMAT_GAMMES_UPDATE: (versionId: string, gammeId?: string) =>
        `/api/v1/back/doormats/versions/${versionId}/gammes/${gammeId}`,
    DOORMAT_GAMMES_DELETE: (versionId: string, gammeId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/gammes/${gammeId}`,

    DOORMAT_REFERENCES: (versionId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/references`,
    DOORMAT_REFERENCE: (versionId: string, referenceId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/references/${referenceId}`,
    DOORMAT_REFERENCES_REORDER_INDEX: (versionId: string) =>
        `/api/v1/back/doormats/versions/${versionId}/references`,
    DOORMAT_REFERENCES_UPDATE: (versionId: string, referenceId?: string) =>
        `/api/v1/back/doormats/versions/${versionId}/references/${referenceId}`,

    //* PRODUITS
    PRODUIT_DETAILS: (code: string) => `/api/v1/back/produits/${code}`,


    LAST_IMPORT_FLUX_FILES: `/api/v1/back/last_import_flux`,
};
