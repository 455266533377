import { RouterState } from 'connected-react-router';
import { useSelector } from 'react-redux';
import * as Redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as ReduxLoger from 'redux-logger';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ThunkMiddleware from 'redux-thunk';

import { Constants } from '../Core/Constants';
import { fr } from '../Core/Locales/fr';
import { AuthenticationMiddleware } from '../Core/Middlewares/AuthenticationMiddleware';
import { MeMiddleware } from '../Core/Middlewares/MeMiddleware';
import { AuthenticationReducer } from '../Core/Services/Data/Authentication/AuthenticationReducer';
import { AuthenticationState } from '../Core/Services/Data/Authentication/typings/AuthenticationState';
import { I18nReducer } from '../Core/Services/Data/I18n/i18nReducer';
import { SUPPORTED_LOCALES } from '../Core/Services/Data/I18n/SetupIntl';
import { LocaleMessageState } from '../Core/Services/Data/I18n/typings/LocaleMessageState';
import { MeReducer } from '../Core/Services/Data/Me/MeReducer';
import { MeState } from '../Core/Services/Data/Me/MeState';
import { RouterReducer } from './browserHistory';

const I18n: LocaleMessageState = {
    defaultLocale: 'fr',
    locale: 'fr',
    supported_locales: SUPPORTED_LOCALES,
    messages: { ...fr },
};

export type AppState = {
    router: RouterState,
    I18n: LocaleMessageState,
    Authentication: AuthenticationState,
    Me: MeState,
};

export let store: Redux.Store;

export type CsmStoreConfig = { store: Redux.Store; persistor: Persistor; };

export class CsmStore {

    public static getStore = (): CsmStoreConfig => {

        const middlewares: Array<Redux.Middleware> = [ThunkMiddleware, AuthenticationMiddleware, MeMiddleware];
        if (Constants.isLocalDev) {
            middlewares.push(ReduxLoger.createLogger({
                //* action from RouterReducer
                predicate: (getState: () => any, action: Redux.AnyAction) => !action.type.includes('@@router'),
            }));
        }

        const rawAppReducer = Redux.combineReducers({
            router: RouterReducer,
            I18n: I18nReducer,
            Authentication: AuthenticationReducer,
            Me: MeReducer,
        });

        const ApplicationReducer = persistReducer({
            key: Constants.AUTH_STORAGE_KEY,
            whitelist: ['Authentication'],
            storage,
        }, rawAppReducer);

        const composeEnhancers = composeWithDevTools({});
        const enhancer = composeEnhancers(Redux.applyMiddleware(...middlewares));

        const initialAppState = { I18n };
        store = Redux.createStore(ApplicationReducer, initialAppState, enhancer);
        const persistor: Persistor = persistStore(store);
        return { store, persistor };
    }
}

export const useAppState = (): AppState => useSelector((state: AppState) => state);